<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <h3 class="my-2">{{ t('notifications') }}</h3>
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <div
            v-if="
              user?.meta?.notifications.filter((n) => !n.read).length ?? 0 > 0
            "
            class="my-2 mx-3 divide-y-2"
          >
            <div
              v-for="notification of user?.meta?.notifications
                .filter((n) => !n.read)
                .slice(0, 5)"
              :key="notification.uid"
            >
              <div class="flex items-center justify-between gap-3 py-2">
                <div class="flex items-center gap-2">
                  <div class="bg-red-500 rounded-full w-2 h-2"></div>
                  <div>
                    <img
                      v-if="notification.picture"
                      class="w-8 h-8 rounded-full"
                      :src="notification.picture"
                    />
                    <svg
                      v-else
                      class="h-8 w-8 bg-gray-100 text-gray-300 rounded-full"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                      />
                    </svg>
                  </div>
                  <div v-if="notification.text.match(/lang\-/i)">
                    {{ t(notification.text.split(' ')[0].split('lang-')[1]) }}
                    <a
                      v-if="notification.text.split(' ').length > 1"
                      :href="
                        '/app/advertiser/orders/' + notification.text.split(' ')[1]
                      "
                      class="underline block text-xs"
                      target="_BLANK"
                      >{{ t('see-order') }}</a
                    >
                  </div>
                  <div v-else>{{ notification.text }}</div>
                </div>
                <div class="text-right">
                  <button @click="read(notification.uid)">
                    <img class="w-4" src="/assets/icons/check.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="my-2 mx-3 flex items-center justify-center">
            {{ t('no-unread-notifications') }}
          </div>
          <div
            :class="[
              'bg-gray-200 py-2 px-3 flex items-center',
              user?.meta?.notifications.filter((n) => !n.read).length ?? 0 > 0
                ? 'justify-between'
                : 'justify-end',
            ]"
          >
            <div>
              <!--<a href="#" class="underline text-sm">
                {{ t('all-notifications') }} >
              </a>-->
            </div>
            <div
              v-if="
                user?.meta?.notifications.filter((n) => !n.read).length ?? 0 > 0
              "
              class="text-right"
            >
              <button class="btn gradient small" @click="readAll">
                {{ t('mark-all-as-read') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import User from '../models/user'

const { t } = useI18n()

const user = ref<User>()
User.getCurrent().then((u) => (user.value = u))

const read = async (uid: string) => {
  await user.value?.read(uid)
  await user.value?.refresh().then((u) => (user.value = u))
}
const readAll = async () => {
  await Promise.all(
    user.value?.meta?.notifications.map((n) => user.value?.read(n.uid)) ?? [],
  )
  await user.value?.refresh().then((u) => (user.value = u))
}
</script>
