<template>
  <Heading :text="campaign?.uid ? t('edit-campaign') : t('create-campaign')" />
  <p v-if="campaign?.uid" v-html="t('campaigns-intro-edit')"></p>
  <p v-else v-html="t('campaigns-intro-add')"></p>
  <div>
    <FormRow
      v-model="name"
      name="name"
      type="text"
      :label="t('campaign-name')"
      required
    />
    <FormRow
      v-model="notes"
      name="name"
      type="textarea"
      :label="t('campaign-notes')"
    />
  </div>
  <div
    class="flex md:justify-center items-center fixed left-0 md:left-64 right-0 bottom-0 p-4 border-t bg-white z-10"
  >
    <button class="btn gradient" @click="save">
      {{ campaign?.uid ? t('edit-campaign') : t('create-campaign') }}
    </button>
  </div>

  <Filters
    @filter="
      (event) =>
        typeof event === 'string'
          ? (filterByName = event)
          : Object.assign(filters, event)
    "
  />
  <div class="overflow-x-scroll">
    <div class="flex items-center justify-end">
      <button
        class="flex items-center btn gradient my-4 text-sm uppercase"
        @click="openChangeColumnsPopup"
      >
        {{ t('change-columns') }}
      </button>
    </div>
    <table ref="root" class="relative w-full text-sm whitespace-nowrap">
      <tr class="-mx-px scale-x-[1.001]">
        <th class="sticky h-20 top-[3.75rem] p-0 font-semibold text-center">
          <div class="h-20 p-0 pt-10 bg-white">
            <div
              class="w-full h-10 p-2 bg-gray-100 border font-bold text-center"
            >
              {{ t('actions') }}
            </div>
          </div>
        </th>
        <th
          v-for="val of enabledColumns"
          :key="val"
          class="sticky h-20 top-[3.75rem] p-0 font-semibold text-center"
        >
          <div class="h-20 p-0 pt-10 bg-white">
            <div
              v-if="val === 'wr'"
              class="w-full h-10 p-2 bg-gray-100 border font-bold text-center whitespace-nowrap flex items-center"
            >
              <img
                src="/assets/icons/wr.png"
                class="h-5 w-5 cursor-pointer"
                @click="openWRInfoPopup()"
              />
              <template v-if="fieldByColumn[val]">
                <!-- prettier-ignore -->
                <button
                  @click="() => {
                    order = fieldByColumn[val];
                    desc = !desc
                  }"
                >
                  <ArrowUpIcon
                    v-if="fieldByColumn[val] === order && !desc"
                    class="w-3 h-3 ml-1"
                  />
                  <ArrowDownIcon v-else class="w-3 h-3 ml-1" />
                </button>
              </template>
            </div>
            <div
              v-else
              class="w-full h-10 p-2 bg-gray-100 border font-bold text-center whitespace-nowrap"
            >
              <span>{{ t(val) }}</span>
              <template v-if="fieldByColumn[val]">
                <!-- prettier-ignore -->
                <button
                    @click="() => {
                      order = fieldByColumn[val];
                      desc = !desc
                    }"
                  >
                    <ArrowUpIcon
                      v-if="fieldByColumn[val] === order && !desc"
                      class="w-3 h-3"
                    />
                    <ArrowDownIcon v-else class="w-3 h-3" />
                  </button>
              </template>
            </div>
          </div>
        </th>
      </tr>
      <ProductItemRow
        v-for="product in products.slice(
          (pagination.current - 1) * pagination.resultsPerPage,
          (pagination.current - 1) * pagination.resultsPerPage +
            pagination.resultsPerPage,
        )"
        :key="product.uid"
        v-model="selectedProducts[product.uid as string]"
        :product="product"
        :enabled-columns="enabledColumns"
        campaign
        @update="fetch"
      />
    </table>
  </div>
  <div v-if="products.length == 0">{{ t('no-products-to-show') }}</div>
  <Pagination
    :pagination="pagination"
    :handle-next="next"
    :handle-prev="prev"
  />
  <div class="h-[200px] w-full"></div>
  <div class="fixed right-10 bottom-10 flex items-center gap-3 z-10">
    <div
      class="shadow-lg bg-primary-500 hover:bg-primary-300 h-9 w-9 p-2 rounded-full text-center lg:w-auto bg-blend-darken text-white cursor-pointer"
    >
      <FilterIcon class="h-5 w-5" @click="scrollTo(0)" />
    </div>
    <div
      class="shadow-lg bg-primary-500 hover:bg-primary-300 h-9 w-9 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white cursor-pointer"
    >
      <ArrowSmUpIcon class="h-6 w-6" @click="scrollTo(0)" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Heading from '../../../components/Heading.vue'
import FormRow from '../../../components/form/FormRow.vue'
import { ref, onMounted, watch, reactive } from 'vue'
import Product from '../../../models/product'
import { useI18n } from 'vue-i18n'
import { orderBy, where } from '@firebase/firestore'
import {
  ArrowDownIcon,
  ArrowSmUpIcon,
  FilterIcon,
  ArrowUpIcon,
} from '@heroicons/vue/solid'
import { useStore } from 'vuex'
import ProductColumnsPopupVue from '../../../components/popups/ProductColumnsPopup.vue'
import { State } from '../../../store'
import ProductItemRow from '../../../components/ProductItemRow.vue'
import Filters from '../../../components/Filters.vue'
import InfoVue from '../../../components/popups/Info.vue'
import LoaderVue from '../../../components/Loader.vue'
import Pagination from '../../../components/Pagination.vue'
import Project from '../../../models/project'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()

const name = ref('')
const notes = ref('')

const route = useRoute()
const selectedProducts = ref<Record<string, boolean>>({})
const store = useStore<State>()
store.commit('popup/open', {
  popup: LoaderVue,
})

const project = ref<Project | null>()
const campaign = ref<any>(null)
Project.find(route.params.project as string).then((res) => {
  project.value = res
  campaign.value = project.value?.campaigns?.find(
    (camp) => camp.uid === route.params.campaign,
  )
  name.value = campaign.value?.name ?? ''
  notes.value = campaign.value?.notes ?? ''
  selectedProducts.value =
    campaign.value?.products.reduce(
      (acc: any, el: any) => ({ ...acc, [el]: true }),
      {},
    ) ?? {}
  store.commit('popoup/close')
})
const router = useRouter()
const save = async () => {
  if (name.value === '') {
    alert(t('campaign-name-error'))
    return
  }
  const selProds = Object.entries(selectedProducts.value)
    .filter((el) => el[1])
    .map((el) => el[0])
  if (!selProds.length) {
    alert(t('campaign-min-media-error'))
    return
  }

  store.commit('popup/open', {
    popup: LoaderVue,
  })
  const camp = await project.value?.upsertCampaign({
    uid: (route.params.campaign || undefined) as string | undefined,
    name: name.value,
    notes: notes.value ?? '',
    products: selProds,
    setup: [
      ...selProds.map((_, i) => {
        const prod = products.value.find((i) => i.uid === _)
        if (campaign.value && campaign.value.setup[i]) return campaign.value.setup[i]
        return {
          needArticle: false,
          extras: prod?.extras ? prod.extras.map(() => false) : [],
        }
      }),
    ],
  })

  router.push(
    `/app/advertiser/projects/${project.value?.uid}/campaigns/${camp?.uid}`,
  )
}

const products = ref<Product[]>([])

const columns = ref<Record<string, boolean>>({
  country: true,
  name: true,
  type: true,
  category: true,
  da: true,
  dr: true,
  za: true,
  gambling: false,
  cbd: false,
  adult: false,
  trading: false,
  gnews: true,
  bl: true,
  edu: false,
  gov: false,
})
const enabledColumns = ref<string[]>([
  'country',
  'name',
  'price',
  'type',
  'category',
  'da',
  'dr',
  'za',
  'gnews',
  'bl',
])
const fieldByColumn = {
  name: 'name',
  price: 'price',
  da: 'da',
  dr: 'dr',
  za: 'za',
  bl: 'bl',
  edu: 'edu',
  gov: 'gov',
  wr: 'wr',
} as Record<string, string>

const order = ref(localStorage.getItem('locale') === 'it' ? 'za' : 'da')
const desc = ref(false)

const openChangeColumnsPopup = () =>
  store.commit('popup/open', {
    popup: ProductColumnsPopupVue,
    columns: columns,
    columnsUpdate: (cols: Record<string, boolean>) => {
      columns.value = cols
      let _enabledColumns = []
      for (let k in cols) if (cols[k]) _enabledColumns.push(k)
      enabledColumns.value = _enabledColumns
    },
  })

const filters = reactive({
  price: [0, 5000] as [number, number],
  da: [0, 100] as [number, number],
  dr: [0, 100] as [number, number],
  za: [0, 100] as [number, number],
  type: '',
  category: '',
  country: '',
  gambling: false,
  cbd: false,
  adult: false,
  trading: false,
  gnews: '',
})
const filterByName = ref<string>('')

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})
const last = ref<Product | undefined>(undefined)
const common_query = [where('approved', '==', true)]

Product.count(common_query).then((total) => {
  pagination.value.total = total
  pagination.value.totalPages = Math.ceil(
    total / pagination.value.resultsPerPage,
  )
})

const next = async () => {
  pagination.value.current++
  document.getElementById('topPage')?.scrollIntoView()
}

const prev = async () => {
  pagination.value.current--
  document.getElementById('topPage')?.scrollIntoView()
}

const fetch = async () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  last.value = products.value[products.value.length - 1]
  const prods = (await Product.searchUnlimited(
    [
      ...common_query,
      orderBy('createdAt', 'desc'),
      where('approved', '==', true),
    ],
    filterByName.value !== '' ? filterByName.value : filters,
    order.value,
    desc.value,
  )) as Product[]
  last.value = prods[prods.length - 1] ?? undefined
  products.value = prods
  pagination.value.current = 1
  pagination.value.total = prods.length
  pagination.value.totalPages = Math.ceil(
    pagination.value.total / pagination.value.resultsPerPage,
  )
  store.commit('popup/close')
}

watch([filters, filterByName, order, desc], () => {
  last.value = undefined
  fetch()
})

onMounted(fetch)

const openWRInfoPopup = () => {
  store.commit('popup/open', { popup: InfoVue, info: 'wolf-rank' })
}

const scrollTo = (y: number) => {
  window.scrollTo(0, y)
}
</script>
