import {
  collection,
  doc,
  DocumentData,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  Timestamp,
  updateDoc,
  startAfter,
  where,
  limit,
} from '@firebase/firestore'
import { firebase } from '../plugins/firebase'
import Model from './model'
import parseDomain from '../plugins/parseDomain'

interface ProductModel {
  uid?: string
  createdAt: Date
  updatedAt: Date
  owner: string
  name: string
  price: number
  description: string
  writeArticle: boolean
  writeArticlePrice: number
  extras: {
    name: string
    price: number
  }[]
  country: string
  website: string[]
  facebook: string[]
  instagram: string[]
  youtube: string[]
  tiktok: string[]
  reddit: string[]
  telegram: string[]
  stats: {
    orders: number
    revenue: number
  }
  accepts: {
    gambling: boolean
    cbd: boolean
    adult: boolean
    trading: boolean
  }
  metrics: {
    likes: number
    followers: number
    ip: string
    dr: number
    da: number
    tf: number
    cf: number
    za: number
    wr: number
    backlinks: {
      total: number
      nofollow: number
      dofollow: number
      gov: number
      edu: number
      refpages: number
      links_internal: number
      links_external: number
    }
  }
  updates: {
    seozoom: Date
    ahrefs: Date
    moz: Date
    screenshot: boolean
    wolfrank: Date
  }
  updated: boolean
  approved: boolean
  canceled?: boolean
  rejected: boolean
  type: string
  screenshot: string
  category: string
  categories: string[]
  google_news: boolean
  only_nofollow?: boolean
  publicationguidelines: string
  publicationduration: string
  avgpubtime: string
  adminFeatured?: boolean
  old_object: any
  promo?: {
    uid?: string
    startDate: Timestamp
    endDate: Timestamp
    discount: number
  }
  featured?: {
    enabled: boolean
    endDate: Timestamp
  }
}

export default class Product extends Model implements ProductModel {
  public collection = 'products'
  static collection = 'products'

  public createdAt: Date
  public updatedAt: Date
  public owner: string
  public name: string
  public price: number
  public writeArticle: boolean
  public writeArticlePrice: number
  public description: string
  public extras: { name: string; price: number }[]
  public country: string
  public website: string[]
  public facebook: string[]
  public instagram: string[]
  public youtube: string[]
  public tiktok: string[]
  public reddit: string[]
  public telegram: string[]
  public stats: { orders: number; revenue: number }
  public accepts: {
    gambling: boolean
    cbd: boolean
    adult: boolean
    trading: boolean
  }
  public metrics: {
    likes: number
    followers: number
    ip: string
    dr: number
    da: number
    tf: number
    cf: number
    za: number
    wr: number
    backlinks: {
      total: number
      nofollow: number
      dofollow: number
      gov: number
      edu: number
      refpages: number
      links_internal: number
      links_external: number
    }
  }
  public updates: {
    seozoom: Date
    ahrefs: Date
    moz: Date
    screenshot: boolean
    wolfrank: Date
  }
  public updated: boolean
  public approved: boolean
  public canceled?: boolean
  public rejected: boolean
  public type: string
  public screenshot: string
  public category: string
  public categories: string[]
  public google_news: boolean
  public only_nofollow?: boolean
  public publicationguidelines: string
  public publicationduration: string
  public avgpubtime: string
  public adminFeatured?: boolean
  public old_object: any
  public promo?: {
    uid?: string
    startDate: Timestamp
    endDate: Timestamp
    discount: number
  }
  public featured?: {
    enabled: boolean
    endDate: Timestamp
  }

  public constructor(attributes: ProductModel) {
    super()
    this.uid = attributes.uid
    this.createdAt =
      attributes.createdAt instanceof Timestamp
        ? attributes.createdAt.toDate()
        : attributes.createdAt
    this.updatedAt =
      attributes.updatedAt instanceof Timestamp
        ? attributes.updatedAt.toDate()
        : attributes.updatedAt
    this.price = attributes.price
    this.name = attributes.name
    this.description = attributes.description
    this.writeArticle = attributes.writeArticle
    this.writeArticlePrice = attributes.writeArticlePrice
    this.extras = attributes.extras.map((extra) => ({
      name: extra.name,
      price: extra.price,
    }))
    this.country = attributes.country
    this.owner = attributes.owner
    this.website = attributes.website
    this.facebook = attributes.facebook
    this.instagram = attributes.instagram
    this.youtube = attributes.youtube
    this.tiktok = attributes.tiktok
    this.reddit = attributes.reddit
    this.telegram = attributes.telegram
    this.stats = attributes.stats
    this.accepts = attributes.accepts
    this.metrics = attributes.metrics
    this.updates = attributes.updates
    this.updated = attributes.updated ?? false
    this.approved = attributes.approved ?? false
    this.canceled = attributes.canceled ?? false
    this.rejected = attributes.rejected ?? false
    this.type = attributes.type ?? ''
    this.screenshot = attributes.screenshot ?? ''
    this.category = attributes.category ?? ''
    this.categories = attributes.categories ?? []
    this.google_news = attributes.google_news ?? false
    this.only_nofollow = attributes.only_nofollow ?? false
    this.publicationguidelines = attributes.publicationguidelines ?? ''
    this.publicationduration = attributes.publicationduration ?? ''
    this.avgpubtime = attributes.avgpubtime ?? ''
    this.adminFeatured = attributes.adminFeatured ?? false
    this.old_object = attributes.old_object ?? {}
    this.promo = attributes.promo
    this.featured = attributes.featured
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as ProductModel
    return new this({
      uid: id,
      ...attrs,
    })
  }

  static publicInstantiate(doc: any) {
    const data = <any>{}
    for (const field of [
      'createdAt',
      'accepts',
      'approved',
      'avgpubtime',
      'category',
      'categories',
      'country',
      'description',
      'extras',
      'facebook',
      'google_news',
      'instagram',
      'metrics',
      'name',
      'price',
      'publicationduration',
      'publicationguidelines',
      'reddit',
      'screenshot',
      'telegram',
      'tiktok',
      'type',
      'website',
      'youtube',
      'owner',
      'promo',
      'adminFeatured',
      'featured',
    ])
      data[field] = doc.get(field)

    const { uid, ...attrs } = data as ProductModel
    return new this({
      uid: doc.id,
      ...attrs,
    })
  }

  static async featured<T extends Model>() {
    if (!this.instantiate) return null
    const firestore = firebase.getFirestore()

    const q = query(
      collection(firestore, this.collection),
      where('approved', '==', true),
      orderBy('createdAt', 'desc'),
      limit(5)
    )
    const data = await getDocs(q)
    const models = data.docs.map(async (d) => {
      const data = <any>{}
      for (const field of [
        'type',
        'website',
        'facebook',
        'instagram',
        'tiktok',
        'reddit',
        'name',
        'price',
        'promo',
        'adminFeatured',
      ])
        data[field] = d.get(field)

      const { uid, ...attrs } = data as ProductModel
      return {
        uid: d.id,
        ...attrs,
      }
    })

    const res = (await Promise.all(models)) as any[]
    return res
    return [
      ...res.filter((i: any) => i.adminFeatured),
      ...res.filter((i: any) => !i.adminFeatured),
    ].splice(0, 5)
  }

  public async approve() {
    updateDoc(doc(collection(firebase.getFirestore(), '/products'), this.uid), {
      approved: true,
    })
  }

  static async searchUnlimited(
    queries: QueryConstraint[] | QueryConstraint,
    filters:
      | {
          price: [number, number]
          da: [number, number]
          dr: [number, number]
          za: [number, number]
          type: string
          category: string
          country: string
          gambling: boolean
          cbd: boolean
          adult: boolean
          trading: boolean
          gnews: string
          match?: string
        }
      | string,
    order = 'createdAt',
    desc = true,
    uids?: string[],
  ) {
    const firestore = firebase.getFirestore()

    const data = [] as DocumentData[]

    // eslint-disable-next-line
    while (true) {
      const q = query(
        collection(firestore, this.collection),
        ...(Array.isArray(queries) ? queries : [queries]),
        ...(data.length ? [startAfter(data[data.length - 1].createdAt)] : []),
      )
      const newDocs = (await getDocs(q)).docs.map((d) =>
        this.publicInstantiate(d),
      )
      data.push(...newDocs)
      if (!newDocs.length) break
    }

    const models = data

    const isMetric = ['da', 'dr', 'za'].includes(order)
    const isBl = ['bl', 'edu', 'gov'].includes(order)

    if (order === 'bl') order = 'total'
    let prods = models
    if (typeof filters === 'string') {
      prods = prods.filter(
        (p) =>
          p.name.match(new RegExp(filters, 'i')) ||
          p.website[0]?.match(new RegExp(filters, 'i')) ||
          p.description?.match(new RegExp(filters, 'i')),
      )
    } else {
      prods = prods
        .filter(
          (p) =>
            !filters.match ||
            filters.match === '' ||
            p.name.match(new RegExp(filters.match, 'i')) ||
            p.website[0]?.match(new RegExp(filters.match, 'i')) ||
            p.description?.match(new RegExp(filters.match, 'i')),
        )
        .filter(
          (p) => p.price <= filters.price[1] && p.price >= filters.price[0],
        )
        .filter(
          (p) =>
            p.type === 'social' ||
            (p.metrics.da <= filters.da[1] && p.metrics.da >= filters.da[0]),
        )
        .filter(
          (p) =>
            p.type === 'social' ||
            filters.country === 'it' ||
            (p.metrics.dr <= filters.dr[1] && p.metrics.dr >= filters.dr[0]),
        )
        .filter(
          (p) =>
            p.type === 'social' ||
            filters.country !== 'it' ||
            (p.metrics.za <= filters.za[1] && p.metrics.za >= filters.za[0]),
        )
        .filter((prod) => !filters.type || prod.type === filters.type)
        .filter(
          (prod) =>
            !filters.category ||
            prod.category === filters.category ||
            prod.categories.includes(filters.category),
        )
        .filter((prod) => !filters.country || prod.country === filters.country)
        .filter((prod) => !filters.gambling || prod.accepts.gambling)
        .filter((prod) => !filters.cbd || prod.accepts.cbd)
        .filter((prod) => !filters.adult || prod.accepts.adult)
        .filter((prod) => !filters.trading || prod.accepts.trading)
        .filter(
          (prod) =>
            filters.gnews === '' ||
            prod.google_news === (filters.gnews === 'yes' ? true : false),
        )
        .filter(
          (prod) => !uids || !uids.length || uids.includes(prod.website[0]),
        )
        .sort((prod1, prod2) => {
          if (prod1.metrics.za === 'not found') prod1.metrics.za = 0
          if (prod2.metrics.za === 'not found') prod2.metrics.za = 0
          const a = isMetric
            ? prod1.metrics[order as 'da' | 'dr' | 'za']
            : isBl
            ? prod1.metrics.backlinks
              ? prod1.metrics.backlinks[order as 'total' | 'edu' | 'gov']
              : 0
            : prod1[
                order as
                  | 'country'
                  | 'name'
                  | 'price'
                  | 'type'
                  | 'category'
                  | 'createdAt'
              ]
          const b = isMetric
            ? prod2.metrics[order as 'da' | 'dr' | 'za']
            : isBl
            ? prod2.metrics.backlinks
              ? prod2.metrics.backlinks[order as 'total' | 'edu' | 'gov']
              : 0
            : prod2[
                order as
                  | 'country'
                  | 'name'
                  | 'price'
                  | 'type'
                  | 'category'
                  | 'createdAt'
              ]
          if (typeof a === 'string' && typeof b === 'string')
            return desc ? a.localeCompare(b) : b.localeCompare(a)
          return desc
            ? Number(a || 0) - Number(b || 0)
            : Number(b || 0) - Number(a || 0)
        })
        .sort((prod1) => {
          if (prod1.adminFeatured) return -1
          if (
            prod1.featured?.enabled &&
            new Date().getTime() <= prod1.featured?.endDate.toDate().getTime()
          )
            return -1
          return 1
        })
    }

    return prods
  }

  /**
   * Return all models
   */
  static async search(
    after: Product | undefined,
    queries: QueryConstraint[] | QueryConstraint,
    filters:
      | {
          price: [number, number]
          da: [number, number]
          dr: [number, number]
          za: [number, number]
          type: string
          category: string
          country: string
          gambling: boolean
          cbd: boolean
          adult: boolean
          trading: boolean
          gnews: string
        }
      | string,
    order = 'createdAt',
    desc = true,
  ) {
    const LIMIT = 20
    const prods = await this.searchUnlimited(queries, filters, order, desc)
    const afterEl = prods.findIndex((mod) => mod.uid === after?.uid) + 1
    return prods.slice(afterEl, afterEl + LIMIT)
  }

  static async favorites(favorites: string[]) {
    const firestore = firebase.getFirestore()

    const data = await getDocs(collection(firestore, this.collection))
    const models = data.docs.map((d) => this.instantiate(d.id, d.data()))

    return models.filter(({ uid }) => (favorites ?? []).includes(uid ?? ''))
  }

  public get hash(): string {

    // Remove spaces and lowercase
    const name = this.name.toLowerCase().trim()
    // Floor price to the nearest 20
    //const price = Math.floor(this.price/20)*20

    return this.type === 'social' ? name : parseDomain(this.website[0], true) as string
  }
}
