<template>
  <nav
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 mr-10"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        {{ t('pagination-showing') }}
        {{ ' ' }}
        <span class="font-medium">{{ showing.from }}</span>
        {{ ' ' }}
        {{ t('pagination-to') }}
        {{ ' ' }}
        <span class="font-medium">{{ showing.to }}</span>
        {{ ' ' }}
        {{ t('pagination-of') }}
        {{ ' ' }}
        <template v-if="!props.hideTotal">
          <span class="font-medium">{{ props.pagination.total }}</span>
          {{ ' ' }}
          {{ t('pagination-results') }}
        </template>
        <template v-else>
          {{ t('many') }}
        </template>
      </p>
    </div>
    <div
      class="flex-1 flex justify-between items-center sm:justify-end space-x-4"
    >
      <button
        v-if="canPrev"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="prev"
      >
        <ArrowLeftIcon class="w-4 h-4" />
      </button>
      <span class="text-sm text-gray-700">{{ props.pagination.current }}</span>
      <button
        v-if="canNext"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="next"
      >
        <ArrowRightIcon class="w-4 h-4" />
      </button>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/outline'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const props = defineProps<{
  pagination: {
    current: number
    total: number
    totalPages: number
    resultsPerPage: number
  }
  hideTotal?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleNext: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  handlePrev: Function
}>()

const next = () => {
  props.handleNext()
}

const prev = () => {
  props.handlePrev()
}

const showing = computed(() => ({
  from:
    props.pagination.current == 1
      ? 1
      : props.pagination.resultsPerPage * (props.pagination.current - 1) + 1,
  to: props.pagination.resultsPerPage * props.pagination.current,
}))

const canNext = computed(
  () => props.pagination.current < props.pagination.totalPages,
)
const canPrev = computed(() => props.pagination.current > 1)
</script>
