<template>
  <Heading :text="t('transactions')" />
  <div
    v-if="loading"
    class="flex justify-center items-center absolute inset-0 bg-white bg-opacity-75"
  >
    <svg
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-500 text-lg"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    Loading...
  </div>
  <div v-else class="flex flex-col">
    <div class="flex items-center justify-between my-4">
      <div class="w-full lg:w-1/3">
        <FormInput
          v-model="filterName"
          name="name"
          type="text"
          placeholder="Inserisci nome utente/id transazione/id ordine"
        />
      </div>
      <div>
        <button class="btn primary" @click="usersToTransactions()">
          {{ t('filter') }}
        </button>
        <button
          class="btn secondary"
          @click="
            () => {
              filterName = ''
              usersToTransactions()
            }
          "
        >
          {{ t('reset') }}
        </button>
      </div>
    </div>
    <div class="flex">
      <PillTabs v-model="filter" i18n :values="filters" />
    </div>
    <div class="flex items-center justify-end gap-3 pl-8 my-4">
      <input
        id="hideCanceled"
        v-model="hideCanceled"
        type="checkbox"
        :value="true"
      />
      <label for="hideCanceled">{{ t('hide-canceled') }}</label>
    </div>
    <div>
      <button v-if="bulkTxn.length" class="btn gradient" @click="confirmBulk">
        {{ t('bulk-confirm') }}
      </button>
      <button v-if="bulkTxn.length" class="btn gradient" @click="rejectBulk">
        {{ t('bulk-reject') }}
      </button>
    </div>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-if="filters[filter] === 'pending'"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  #
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  User
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Description
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Payment Method
                </th>
                <th
                  v-if="filters[filter] === 'withdrawals'"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  v-else
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Type
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Invoice
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="trans in filteredTransactions.sort((a: any, b: any) => (a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1)).slice(
                  (pagination.current - 1) * pagination.resultsPerPage,
                  (pagination.current - 1) * pagination.resultsPerPage +
                    pagination.resultsPerPage,
                )"
                :key="trans.uid"
                class="odd:bg-white even:bg-gray-50"
              >
                <td
                  v-if="filters[filter] === 'pending-payments'"
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  <input
                    type="checkbox"
                    :value="trans.uid"
                    class="bulkTxCheckboxes"
                    @input="updateBulkApproveTx($event)"
                  />
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  <span
                    :title="trans.status"
                    class="relative inline-flex h-3 w-3 mr-1"
                  >
                    <span
                      class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                      :class="{
                        'bg-red-500': trans.status === 'rejected',
                        'bg-yellow-500': trans.status === 'pending',
                        'bg-emerald-500': trans.status === 'completed',
                      }"
                    ></span>
                    <span
                      class="relative inline-flex rounded-full h-3 w-3"
                      :class="{
                        'bg-red-500': trans.status === 'rejected',
                        'bg-yellow-500': trans.status === 'pending',
                        'bg-emerald-500': trans.status === 'completed',
                      }"
                    ></span>
                  </span>
                  {{ trans.user.displayName }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ new Date(trans.createdAt).format() }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ trans.description }}
                  <span v-if="trans.rejectReason"
                    ><br /><strong>{{ t('reject-reason') }}: </strong
                    >{{ trans.rejectReason }}</span
                  >
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ t(trans.paymentMethod as string) }}
                </td>
                <td
                  v-if="filters[filter] === 'withdrawals'"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  <span v-if="trans.withdrawalDate">{{
                    new Date(trans.withdrawalDate.seconds * 1000).format(true)
                  }}</span>
                  <span v-else>N.D.</span>
                </td>
                <td
                  v-else
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                >
                  {{ t(trans.type as string) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span
                    v-if="
                      trans.type === 'purchase' &&
                      trans.paymentMethod !== 'balance'
                    "
                    class="text-emerald-500"
                  >
                    {{ (-trans.amount).format() }}
                  </span>
                  <span
                    v-else-if="
                      trans.type === 'purchase' &&
                      trans.paymentMethod === 'balance'
                    "
                  >
                    {{ (-trans.amount).format() }}
                  </span>
                  <span
                    v-else-if="trans.type === 'deposit'"
                    class="text-emerald-500"
                  >
                    {{ trans.amount.format() }}
                  </span>
                  <span
                    v-else-if="
                      trans.type === 'order' || trans.type === 'withdrawal'
                    "
                    class="text-red-500"
                  >
                    {{
                      (
                        (trans.type === 'order' ? -1 : 1) * trans.amount
                      ).format()
                    }}
                  </span>
                  <span
                    v-else-if="trans.type === 'refund' && trans.amount < 0"
                    class="text-red-500"
                  >
                    {{ (-trans.amount).format() }}
                  </span>
                  <span
                    v-else-if="trans.type === 'refund' && trans.amount > 0"
                    class="text-emerald-500"
                  >
                    {{ (-trans.amount).format() }}
                  </span>
                  <span v-else>{{ trans.amount.format() }}</span>
                </td>
                <td
                  class="px-6 py-4 text-sm text-gray-500 flex items-center gap-2"
                >
                  <a
                    v-if="trans.invoice"
                    :href="trans.invoice"
                    target="_blank"
                    class="text-primary-500 hover:text-primary-600"
                  >
                    PDF
                  </a>
                  <button
                    v-if="
                      trans.type !== 'pending' &&
                      trans.type !== 'withdrawal' &&
                      trans.type !== 'order' &&
                      (trans.type === 'deposit' || trans.type === 'purchase') &&
                      trans.status !== 'pending'
                    "
                    @click="resendInvoice(trans.user.uid, trans.uid)"
                  >
                    <DocumentIcon class="h-6 w-6" />
                  </button>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <template
                    v-if="
                      trans.status === 'pending' &&
                      trans.paymentMethod !== 'balance' &&
                      trans.type !== 'order'
                    "
                  >
                    <button
                      class="text-emerald-600 w-1/3 text-center lg:w-auto"
                      @click="confirm(trans.user.uid, trans.uid, trans.type)"
                    >
                      <CheckCircleIcon class="h-6 w-6" />
                    </button>
                    <button
                      class="text-red-600 w-1/3 text-center lg:w-auto"
                      @click="
                        reject(
                          trans.user.uid,
                          trans.uid,
                          trans.type,
                          trans.orderId,
                        )
                      "
                    >
                      <XCircleIcon class="h-6 w-6" />
                    </button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :pagination="pagination"
          :handle-next="next"
          :handle-prev="prev"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import Heading from '../../components/Heading.vue'
import User, { Meta } from '../../models/user'
import {
  XCircleIcon,
  CheckCircleIcon,
  DocumentIcon,
} from '@heroicons/vue/solid'
import {
  collection,
  doc,
  enableMultiTabIndexedDbPersistence,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from '@firebase/firestore'
import { useFirebase } from '../../plugins/firebase'
import { useI18n } from 'vue-i18n'
import TxStats from '../../components/admin/TxStats.vue'
import PillTabs from '../../components/PillTabs.vue'
import Pagination from '../../components/Pagination.vue'
import { useRoute } from 'vue-router'
import FormInput from '../../components/form/FormInput.vue'
import Order from '../../models/order'

const { t } = useI18n()
const users = ref<User[]>()
const transactions = ref<Meta['transactions'] | undefined>()
const filteredTransactions = ref<any>()
const loading = ref<boolean>(true)
const filter = ref(0)
const hideCanceled = ref<boolean>(false)
const filters = [
  'all',
  'purchases',
  'deposits',
  'publisher',
  'refunds',
  'withdrawals',
  'pending-withdrawals',
  'pending-payments',
  'affiliate',
  'adjustments',
]
const filterDate = ref<{
  from?: string
  to?: string
}>({
  to: undefined,
  from: undefined,
})
const filterName = ref<string>()
const bulkTxn = ref<string[]>([])
const route = useRoute()
const filterByUser = ref<string | null>((route.query.user as string) || null)

const updateBulkApproveTx = (e: Event) => {
  if (
    (e.target as any).checked &&
    !bulkTxn.value.includes((e.target as any).value)
  )
    bulkTxn.value.push((e.target as any).value)
  else if (
    !(e.target as any).checked &&
    bulkTxn.value.includes((e.target as any).value)
  )
    bulkTxn.value.splice(bulkTxn.value.indexOf((e.target as any).value), 1)
}

const updateUsers = () => {
  loading.value = true
  User.all().then((res) => {
    users.value = res as User[]
    usersToTransactions()
    loading.value = false
  })
}

onMounted(() => {
  updateUsers()
})

const usersToTransactions = () => {
  transactions.value = undefined
  let _transactions = []
  for (let user of users.value ?? []) {
    for (let trans of user.meta?.transactions ?? []) {
      _transactions.push({ ...trans, user: user })
    }
  }

  if (filterDate.value.from || filterDate.value.to) {
    _transactions = _transactions.filter((i) => {
      if (filterDate.value.from && !filterDate.value.to)
        return (
          i.createdAt.getTime() >=
          new Date(new Date(filterDate.value.from).setHours(0, 0, 0)).getTime()
        )
      else if (!filterDate.value.from && filterDate.value.to) {
        return (
          i.createdAt.getTime() <=
          new Date(new Date(filterDate.value.to).setHours(23, 59, 59)).getTime()
        )
      } else if (filterDate.value.from && filterDate.value.to)
        return (
          i.createdAt.getTime() >=
            new Date(
              new Date(filterDate.value.from).setHours(0, 0, 0),
            ).getTime() &&
          i.createdAt.getTime() <=
            new Date(
              new Date(filterDate.value.to).setHours(23, 59, 59),
            ).getTime()
        )
    })
  }
  if (filterName.value && filterName.value !== '') {
    _transactions = _transactions.filter((i: any) => {
      try {
        return (
          i.user.uid === filterName.value ||
          i.user.displayName?.match(
            new RegExp(filterName.value as string, 'i'),
          ) ||
          i.user.meta?.invoicingAccounts
            .find((iA: any) => iA.uid === i.invoicingAccount)
            ?.address.match(new RegExp(filterName.value as string, 'i')) ||
          i.user.meta?.invoicingAccounts
            .find((iA: any) => iA.uid === i.invoicingAccount)
            ?.organization?.match(
              new RegExp(filterName.value as string, 'i'),
            ) ||
          i.uid.match(new RegExp(filterName.value as string, 'i')) ||
          i.orderId?.match(new RegExp(filterName.value as string, 'i'))
        )
      } catch (e) {
        console.log(i.user, e)
        return false
      }
    })
  }
  transactions.value = _transactions
  filterTransactions()
}

const filterTransactions = () => {
  if (!transactions.value) return
  let _transactions = [...(transactions.value as Meta['transactions'])]

  if (filterByUser.value)
    _transactions = _transactions.filter(
      (i: any) => i.user.uid === filterByUser.value,
    )
  if (hideCanceled.value)
    _transactions = _transactions.filter((i) => i.status !== 'rejected')
  _transactions = _transactions.sort((a: any, b: any) =>
    filters[filter.value] === 'withdrawals'
      ? a.status === 'pending' && b.status !== 'pending'
        ? -1
        : 1
      : new Date(a.createdAt) > new Date(b.createdAt)
      ? -1
      : 1,
  )

  if (filters[filter.value] === 'all')
    filteredTransactions.value = _transactions
  else if (filters[filter.value] === 'purchases')
    filteredTransactions.value = _transactions.filter(
      (i) => i.type === 'purchase',
    )
  else if (filters[filter.value] === 'deposits')
    filteredTransactions.value = _transactions.filter(
      (i) => i.type === 'deposit',
    )
  else if (filters[filter.value] === 'publisher')
    filteredTransactions.value = _transactions.filter((i) => i.type === 'order')
  else if (filters[filter.value] === 'refunds')
    filteredTransactions.value = _transactions.filter(
      (i) => i.type === 'refund',
    )
  else if (filters[filter.value] === 'withdrawals')
    filteredTransactions.value = _transactions.filter(
      (i) => i.type === 'withdrawal' && i.status === 'completed',
    )
  else if (filters[filter.value] === 'pending-withdrawals')
    filteredTransactions.value = _transactions.filter(
      (i) =>
        i.status === 'pending' &&
        i.type !== 'order' &&
        i.paymentMethod === 'withdrawal',
    )
  else if (filters[filter.value] === 'pending-payments')
    filteredTransactions.value = _transactions.filter(
      (i) =>
        i.status === 'pending' &&
        i.type !== 'order' &&
        i.paymentMethod !== 'withdrawal',
    )
  else if (filters[filter.value] === 'affiliate')
    filteredTransactions.value = _transactions.filter(
      (i) => i.type === 'affiliate',
    )
  else if (filters[filter.value] === 'adjustments')
    filteredTransactions.value = _transactions.filter(
      (i) => i.type === 'adjustment',
    )

  pagination.value.total = filteredTransactions.value.length
  pagination.value.totalPages = Math.ceil(
    filteredTransactions.value.length / pagination.value.resultsPerPage,
  )
}
watch([filter, hideCanceled], () => {
  pagination.value.current = 1
  filterTransactions()
})

const firebase = useFirebase()
const firestore = firebase.getFirestore()

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})

const updateBalance = async (
  user: string,
  trans: string,
  type: 'deposit' | 'withdrawal' | 'purchase' | 'order',
) => {
  const model = users.value?.find((u) => u.uid === user)
  const sign = type === 'withdrawal' ? -1 : 1
  await updateDoc(doc(firestore, `/users/${user}`), {
    balance:
      (model?.meta?.balance as number) +
      sign *
        (model?.meta?.transactions?.find((t) => t.uid === trans)
          ?.amount as number),
  })
}
const confirm = async (
  user: string,
  trans: string,
  type: 'deposit' | 'withdrawal' | 'purchase' | 'order',
) => {
  loading.value = true
  if (type === 'withdrawal') {
    const info = prompt('Vuoi inserire delle informazioni?')
    await updateDoc(doc(firestore, `/users/${user}/transactions/${trans}`), {
      status: 'completed',
      withdrawalDate: new Date(),
      ...(info ? { withdrawalInfo: info } : {}),
    })
  } else
    await updateDoc(doc(firestore, `/users/${user}/transactions/${trans}`), {
      status: 'completed',
    })

  if (type === 'deposit') {
    await updateBalance(user, trans, type)
    await firebase.function('sendInvoice', {
      transId: trans,
      userId: user,
    })
  }
  if (type === 'purchase') {
    const transaction = (
      await getDoc(doc(firestore, `/users/${user}/transactions/${trans}`))
    ).data()
    if (transaction?.orderId) {
      await updateDoc(doc(firestore, `/orders/${transaction.orderId}`), {
        status: 'paid',
      })
      await firebase.function('ordersCampaignInfo', {
        orders: [transaction?.orderId],
      })
    }
    await firebase.function('sendInvoice', {
      transId: trans,
      userId: user,
    })
  }
  if (type === 'withdrawal')
    await firebase.function('withdrawalNotify', { user, trans })
  updateUsers()
}

const confirmBulk = async () => {
  loading.value = true
  const _bulkTrans = (transactions.value?.filter((i: any) =>
    bulkTxn.value.includes(i.uid),
  ) ?? []) as any
  for (const trans of _bulkTrans) {
    await updateDoc(
      doc(firestore, `/users/${trans.user.uid}/transactions/${trans.uid}`),
      {
        status: 'completed',
      },
    )

    if (trans.type === 'deposit') {
      await firebase.function('sendInvoice', {
        transId: trans.uid,
        userId: trans.user.uid,
      })
      await updateBalance(trans.user.uid, trans.uid, trans.type)
    }
    if (trans.type === 'purchase') {
      const transaction = (
        await getDoc(
          doc(firestore, `/users/${trans.user.uid}/transactions/${trans.uid}`),
        )
      ).data()
      if (transaction?.orderId) {
        await updateDoc(doc(firestore, `/orders/${transaction?.orderId}`), {
          status: 'paid',
        })
      }

      await firebase.function('sendInvoice', {
        transId: trans.uid,
        userId: trans.user.uid,
      })
    }
  }
  const purchaseBulkTxn = _bulkTrans.filter((i: any) => i.type === 'purchase')
  if (purchaseBulkTxn.length)
    await firebase.function('ordersCampaignInfo', {
      orders: [purchaseBulkTxn.map((i: any) => i.order)],
    })
  bulkTxn.value = []
  updateUsers()
}
const reject = async (
  user: string,
  trans: string,
  type: 'deposit' | 'withdrawal' | 'purchase' | 'order',
  orderId?: string,
) => {
  if (!window.confirm('Sei sicuro di voler rifiutare questo pagamento?')) return
  loading.value = true
  if (type === 'withdrawal') {
    const reason = prompt('Inserisci la motivazione del rifiuto.')
    await updateDoc(doc(firestore, `/users/${user}/transactions/${trans}`), {
      status: 'rejected',
      withdrawalDate: new Date(),
      rejectReason: reason,
    })
  } else {
    await updateDoc(doc(firestore, `/users/${user}/transactions/${trans}`), {
      status: 'rejected',
    })
    if (orderId && type === 'purchase') {
      const order = (
        await getDoc(doc(firestore, `/orders/${orderId}`))
      ).data() as Order
      if (!order) alert('ORDER NOT FOUND ' + orderId)
      else {
        await updateDoc(doc(firestore, `/orders/${orderId}`), {
          status: 'canceled',
        })
        const pubTrans = (
          await getDocs(
            query(
              collection(firestore, `/users/${order.publisher}/transactions`),
              where('orderId', '==', orderId),
              where('type', '==', 'order'),
            ),
          )
        ).docs
        if (pubTrans.length != 1) {
          alert('PIU TRANSAZIONI PUBLISHER PER STESSO ORDINE??')
        } else {
          const pubTransOne = pubTrans[0].id
          await updateDoc(
            doc(
              firestore,
              `/users/${order.publisher}/transactions/${pubTransOne}`,
            ),
            {
              status: 'rejected',
            },
          )
        }
      }
    }
  }

  if (type === 'withdrawal') updateBalance(user, trans, type)
  if (type === 'withdrawal')
    firebase.function('withdrawalNotify', { user, trans })

  updateUsers()
}

const rejectBulk = async () => {
  if (!window.confirm('Sei sicuro di voler rifiutare questi pagamenti?')) return
  loading.value = true
  const _bulkTrans = (transactions.value?.filter((i: any) =>
    bulkTxn.value.includes(i.uid),
  ) ?? []) as any
  const reason = prompt(
    'Inserisci la motivazione del rifiuto, verrà dato solo se la tipologia è un prelievo.',
  )
  for (const trans of _bulkTrans) {
    if (trans.type === 'withdrawal') {
      await updateDoc(
        doc(firestore, `/users/${trans.user.uid}/transactions/${trans.uid}`),
        {
          status: 'rejected',
          withdrawalDate: new Date(),
          rejectReason: reason,
        },
      )
    } else {
      await updateDoc(
        doc(firestore, `/users/${trans.user.uid}/transactions/${trans.uid}`),
        {
          status: 'rejected',
        },
      )
      if (trans.type === 'purchase') {
        const transaction = (
          await getDoc(
            doc(
              firestore,
              `/users/${trans.user.uid}/transactions/${trans.uid}`,
            ),
          )
        ).data()
        if (transaction?.orderId) {
          await updateDoc(doc(firestore, `/orders/${transaction?.orderId}`), {
            status: 'canceled',
          })
          const order = (
            await getDoc(doc(firestore, `/orders/${transaction?.orderId}`))
          ).data() as Order
          if (!order) alert('ORDER NOT FOUND ' + transaction?.orderId)
          else {
            const pubTrans = (
              await getDocs(
                query(
                  collection(
                    firestore,
                    `/users/${order.publisher}/transactions`,
                  ),
                  where('orderId', '==', transaction?.orderId),
                  where('type', '==', 'order'),
                ),
              )
            ).docs
            if (pubTrans.length != 1) {
              alert('PIU TRANSAZIONI PUBLISHER PER STESSO ORDINE??')
            } else {
              const pubTransOne = pubTrans[0].id
              await updateDoc(
                doc(
                  firestore,
                  `/users/${order.publisher}/transactions/${pubTransOne}`,
                ),
                {
                  status: 'rejected',
                },
              )
            }
          }
        }
      }
    }

    if (trans.type === 'withdrawal')
      await updateBalance(trans.user.uid, trans.uid, trans.type)
    if (trans.type === 'withdrawal')
      firebase.function('withdrawalNotify', {
        user: trans.user.uid,
        trans: trans.uid,
      })
  }
  bulkTxn.value = []
  updateUsers()
}

const resendInvoice = async (user: string, transaction: string) => {
  if (
    !window.confirm(
      'Stai riemettendo una fattura, questo è molto pericoloso. Sei sicuro?',
    )
  )
    return

  loading.value = true
  await firebase.function('resendInvoice', { user, transaction })
  await updateUsers()
}

const next = async () => {
  pagination.value.current++
}

const prev = async () => {
  pagination.value.current--
}
</script>
