<template>
  <div>
    <Heading :text="t('products')" />
    <div class='flex mb-4 text-gray-800'>
      <PillTabs
        :i18n='true'
        :model-value='
          Object.keys(filters).findIndex((i) => i === currentFilter)
        '
        :values='Object.keys(filters)'
        @update:model-value='currentFilter = Object.keys(filters)[$event]'
      />
    </div>
    <button
      v-if="currentFilter === 'entire-db'"
      class='btn bg-emerald-500 small text-white'
      @click='exportMediaToCSV'
    >
      {{ t('export-all-to-csv') }}
    </button>

    <ProductsApproved v-if="currentFilter === 'approved'" :users='users' />
    <ProductsToApprove v-if="currentFilter === 'to-approve'" :users='users' />
    <ProductsPendingCron v-if="currentFilter === 'pending-cron'" :users='users' />
    <ProductsCanceled v-if="currentFilter === 'canceled'" :users='users' />
    <ProductsRejected v-if="currentFilter === 'rejected'" :users='users' />
    <ProductsEntireDB v-if="currentFilter === 'entire-db'" :users='users' />
    <ProductsDuplicate v-if="currentFilter === 'duplicate'" :users='users' />
  </div>
</template>

<script lang='ts' setup>
import { ref } from 'vue'
import Heading from '../../components/Heading.vue'
import { useI18n } from 'vue-i18n'
import PillTabs from '../../components/PillTabs.vue'
import LoaderVue from '../../components/Loader.vue'
import { State } from '../../store'
import { useStore } from 'vuex'
import { useFirebase } from '../../plugins/firebase'
import ProductsEntireDB from '../../components/admin/ProductsEntireDB.vue'
import ProductsRejected from '../../components/admin/ProductsRejected.vue'
import ProductsPendingCron from '../../components/admin/ProductsPendingCron.vue'
import ProductsApproved from '../../components/admin/ProductsApproved.vue'
import ProductsToApprove from '../../components/admin/ProductsToApprove.vue'
import ProductsCanceled from '../../components/admin/ProductsCanceled.vue'
import User from '../../models/user'
import ProductsDuplicate from '../../components/admin/ProductsDuplicate.vue'

const { t } = useI18n()
const store = useStore<State>()

const currentFilter = ref<string>('approved')
const filters = {
  'approved': [], //[where('approved', '==', true)],
  'to-approve': [], //[where('approved', '==', false), where('updated', '==', true)],
  'pending-cron': [], //[where('updated', '==', false)],
  'rejected': [], //[where('rejected', '==', true)],
  'canceled': [], //[where('canceled', '==', true)],
  'entire-db': [],
  'duplicate': [],
}

const users = ref<User[]>([])
User.allLight().then((res) => {
  users.value = res as User[]
})
const firebase = useFirebase()

const exportMediaToCSV = async () => {
  try {
    store.commit('popup/open', { popup: LoaderVue })
    const res = await firebase.function('exportMediaToCSV')
    const tmp_a = document.createElement('a')
    tmp_a.href = window.URL.createObjectURL(
      new Blob([(res as string[]).join('\n')]),
    )
    tmp_a.download = 'export-data.tsv'
    tmp_a.click()
    store.commit('popup/close')
  } catch (e) {
    console.error(e)
    alert('fai screenshot console')
  }
}
</script>
