<template>
  <div
    class="group py-10 border-b last:border-none border-gray-200 even:bg-gray-50 hover:static-gradient-transparent px-4 lg:px-10 flex flex-col lg:flex-row gap-6 md:gap-0 lg:items-center space-x-6"
  >
    <div class="flex flex-grow items-center gap-6">
      <div class="flex-auto flex flex-col">
        <div>
          <h4 class="font-medium text-gray-900 flex">
            <span v-if="product_collection === 'products'">
              {{ (product as Product)?.name }}</span
            >
            <span v-else-if="product_collection === 'articles'">
              {{ t('copywriting-service') }}
            </span>
            <span v-else-if="product_collection === 'presses'">
              {{ t('press-release') }} {{ (product as Press)?.name }}
            </span>
            <div class="flex gap-1 mx-2">
              <img
                v-for="ty of types"
                :key="ty"
                :src="`/assets/icons/${ty}.svg`"
                width="16"
                height="16"
              />
            </div>
          </h4>
        </div>
        <div
          v-if="
            order.extras &&
            product &&
            'extras' in product &&
            (order.extras ?? []).reduce((acc, e) => acc || e, false)
          "
          class="mt-6 flex-1 flex items-end text-sm divide-x divide-gray-200 space-x-4 sm:space-x-6"
        >
          <div
            v-for="(extra, i) of product?.extras.filter(
              (_, i) => (order.extras as boolean[])[i],
            )"
            :key="extra.name"
            class="font-medium text-gray-900"
            :class="{ 'pl-4 sm:pl-6': i !== 0 }"
          >
            {{ extra.name }}
          </div>
        </div>
        <div v-if="'words' in order && order.words">
          {{ order.words }} {{ t('words') }}
        </div>
        <div v-if="(isAdmin || isSoftAdmin) && order.feedback">
          <a href="#" class="underline" @click.prevent="openFeedbackPopup(order.feedback)">Review</a>
        </div>
        <div v-if="(isAdmin || isSoftAdmin) && (props.linkedOrder || order.linkedOrder)">
          {{ t('order-linked-to') }} #{{
            props.linkedOrder || order.linkedOrder
          }}
        </div>
        <div v-if="(isAdmin || isSoftAdmin) && props.order.campaign && props.order.project">
          <a target="_BLANK" class="underline" :href="'/app/admin/projects/' + props.order.project + '/campaigns/' + props.order.campaign">Campaign Link</a>
        </div>
        <div class="my-2 text-sm">
          <!--<span v-if="order.product_collection === 'products'"></span>-->
          <span
            v-if="
              !isPublisher &&
              order.status === 'pending' &&
              (order.paymentMethod === 'paypal' ||
                order.paymentMethod === 'stripe') &&
              !('created' in useRoute().query)
            "
          >
            <a
              href="#"
              class="underline text-red-500"
              @click.prevent="orderPayment"
              >{{ t('payment-problems-try-again') }}</a
            ></span
          >
        </div>
        <div class="my-2 flex-1 flex items-end">
          <dl
            class="flex flex-col lg:flex-row text-sm lg:divide-x divide-gray-200 lg:space-x-6"
          >
            <div class="flex">
              <dt class="font-medium text-gray-900">
                <span class="relative inline-flex h-3 w-3 mr-1">
                  <span
                    class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                    :class="{
                      'bg-yellow-500':
                        order.status !== 'completed' &&
                        order.status !== 'canceled' &&
                        order.status !== 'refunded',
                      'bg-emerald-500': order.status === 'completed',
                      'bg-red-500':
                        order.status === 'canceled' ||
                        order.status === 'refunded',
                    }"
                  ></span>
                  <span
                    class="relative inline-flex rounded-full h-3 w-3"
                    :class="{
                      'bg-yellow-500':
                        order.status !== 'completed' &&
                        order.status !== 'canceled' &&
                        order.status !== 'refunded',
                      'bg-emerald-500': order.status === 'completed',
                      'bg-red-500':
                        order.status === 'canceled' ||
                        order.status === 'refunded',
                    }"
                  ></span>
                </span>
                {{ t('status') }}:
              </dt>
              <dd
                v-if="
                  isPublisher &&
                  order.extras &&
                  !order.article &&
                  order.status === 'paid'
                "
                class="ml-2 text-gray-700"
              >
                {{ t('pending-instructions') }}
              </dd>
              <dd
                v-else
                class="ml-2 text-gray-700"
                :data-order-id="order.status"
              >
                {{ t(order.status) }}
              </dd>
            </div>
            <div class="lg:pl-4 flex sm:pl-6">
              <dt class="font-medium text-gray-900">{{ t('payment') }}:</dt>
              <dd class="ml-2 text-gray-700">
                <span v-if="order.status === 'pending'" class="text-red-500">{{
                  t('payment-pending')
                }}</span>
                <span v-else-if="order.status === 'canceled'">{{
                  t('payment-canceled')
                }}</span>
                <span v-else>{{ t('payment-received') }}</span>
              </dd>
            </div>
            <div class="lg:pl-4 flex sm:pl-6">
              <dt class="font-medium text-gray-900">{{ t('date') }}:</dt>
              <dd class="ml-2 text-gray-700">
                {{ order.createdAt.format() }}
              </dd>
            </div>
          </dl>
        </div>
        <div
          v-if="order.refused && order.status === 'work-in-progress'"
          class="text-red-500 text-sm"
        >
          Rifiutato
        </div>
        <div class="flex mt-1 text-sm">
          <dt class="font-medium text-gray-900">{{ t('order-id') }}:</dt>
          <dd class="ml-2 text-gray-700">
            {{ order.uid }}
          </dd>
        </div>
        <div v-if="isAdmin && order.paymentMethod" class="flex mt-1 text-sm">
          <dt class="font-medium text-gray-900">{{ t('payment-method') }}:</dt>
          <dd class="ml-2 text-gray-700">
            {{ t(order.paymentMethod) }}
          </dd>
        </div>
        <div v-if="isAdmin" class="flex mt-1 text-sm">
          <dt class="font-medium text-gray-900">{{ t('owner') }}:</dt>
          <dd class="ml-2 text-gray-700">
            <a
              class="underline"
              target="_BLANK"
              :href="'/app/admin/users/' + order.owner"
              >{{
                owner && owner.displayName
                  ? owner.displayName
                  : owner && owner.uid
                  ? owner.uid
                  : order.owner
              }}</a
            >
          </dd>
        </div>
        <div v-if="isAdmin" class="flex mt-1 text-sm">
          <dt class="font-medium text-gray-900">{{ t('publisher') }}:</dt>
          <dd class="ml-2 text-gray-700">
            <a
              class="underline"
              target="_BLANK"
              :href="'/app/admin/users/' + order.publisher"
              >{{
                publisher && publisher.displayName && publisher.displayName.trim() !== ''
                  ? publisher.displayName
                  : publisher && publisher.email
                  ? publisher.email
                  : order.publisher
              }}</a
            >
          </dd>
        </div>
        <div v-if="isAdmin" class="text-sm">
          {{ t('rankister-margin') }}: &euro;
          {{ (order.owner_fee * order.price ?? 0).format() }}
        </div>
      </div>
      <div class="flex text-xl font-bold whitespace-nowrap">
        {{ order.price?.format() }} €<br />
      </div>
    </div>
    <div class="flex items-center justify-evenly gap-2">
      <div
        v-if="(!isPublisher && order.status === 'pending') || (!isPublisher && order.status === 'paid' && !order.advertiserInfo)"
        class="h-7 w-7 p-1 rounded-full text-red-500"
      >
        <ExclamationIcon class="w-6 h-6" />
      </div>
      <button
        v-if="
          order.status !== 'canceled' &&
          order.status !== 'pending' &&
          order.status !== 'refunded' &&
          (!isPublisher || order.extras || !order.extras)
        "
        class="gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="openPopup"
      >
        <EyeIcon class="w-4 h-4" />
      </button>
      <button
        v-if="order.status !== 'pending'"
        class="relative gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="chat"
      >
        <div
          v-if="
            chatModel &&
            chatModel.messages.filter((i) => i.from != user?.uid && !i.read)
              .length
          "
          class="bg-gray-100 w-5 h-5 flex items-center justify-center rounded-full shadow-lg absolute top-[-10px] right-[-10px] text-black text-[11px]"
        >
          <span>{{
            chatModel.messages.filter((i) => i.from != user?.uid && !i.read)
              .length
          }}</span>
        </div>
        <ChatAlt2Icon class="w-4 h-4" />
      </button>
      <button
        v-if="
          isAdmin &&
          ['paid', 'work-in-progress', 'need-review', 'completed'].includes(
            order.status,
          )
        "
        class="bg-purple-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="refundOrder"
      >
        <ReceiptRefundIcon class="w-4 h-4" />
      </button>
      <!--<button
        v-if="
          isAdmin
        "
        class="bg-red-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="deleteOrder"
      >
        <XCircleIcon class="w-4 h-4" />
      </button>-->
    </div>
  </div>
  <div v-if="props.linkedOrders" class="pl-12">
    <OrderItem
      v-for="lo in props.linkedOrders"
      :key="lo.uid"
      :order="lo"
      :linked-order="props.order.uid"
    />
  </div>
</template>
<script lang="ts">
export default defineComponent({ name: 'OrderItem' })
</script>
<script lang="ts" setup>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Order from '../models/order'
import Product from '../models/product'
import { useFirebase } from '../plugins/firebase'
import {
  EyeIcon,
  ExclamationIcon,
  ChatAlt2Icon,
  ReceiptRefundIcon,
} from '@heroicons/vue/solid'
import { useI18n } from 'vue-i18n'
import Article from '../models/article'
import store from '../store'
import OrderPopup from './popups/OrderPopup.vue'
import Loader from './Loader.vue'
import Chat from '../models/chat'
import ChatPopup from './popups/ChatPopup.vue'
import Press from '../models/press'
import { useRoute } from 'vue-router'
import LoaderVue from './Loader.vue'
import RefuseOrder from './popups/RefuseOrder.vue'
import OrderAdminPopup from './popups/OrderAdminPopup.vue'
import User from '../models/user'
import FeedbackListPopup from './popups/FeedbackListPopup.vue'

const { t } = useI18n()
const props = defineProps<{
  order: Order
  users?: any
  linkedOrders?: Order[]
  linkedOrder?: string
  user?: User
}>()

const chatModel = ref<Chat>()
Chat.find(props.order.uid).then((chat) => {
  chat?.loadMessages().then(() => chatModel.value = chat as Chat)
})

const emit = defineEmits(['update'])

const product = ref<Product | Article | Press>()
const product_collection = ref<string>(
  props.order.product_collection ??
    (props.order.extras ? 'products' : 'articles'),
)
if (product_collection.value === 'products') {
  if (props.order.product_object)
    product.value = props.order.product_object as Product
  else
    Product.find(props.order.product).then(
      (prod) => (product.value = prod as Product),
    )
} else if (product_collection.value === 'articles') {
  if (props.order.product_object)
    product.value = props.order.product_object as Article
  else
    Article.find(props.order.product).then(
      (art) => (product.value = art as Article),
    )
} else if (product_collection.value === 'presses') {
  if (props.order.product_object)
    product.value = props.order.product_object as Press
  else
    Press.find(props.order.product).then(
      (art) => (product.value = art as Press),
    )
}
const types = computed(() =>
  [
    'website',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'reddit',
    'telegram',
  ].filter(
    (type) => (product.value as unknown as Record<string, []>)?.[type]?.length,
  ),
)

const isPublisher = useStore().state.AccountType.type === 'publisher'
const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')
const firebase = useFirebase()

const openPopup = () =>
  store.commit('popup/open', {
    popup: isAdmin || isSoftAdmin ? OrderAdminPopup : OrderPopup,
    order: props.order,
    orderDone: popupOrderDone,
    orderRefused: popupOrderRefused,
    orderChange: popupOrderChange,
  })

onMounted(() => {
  if (useRoute().params.uid) {
    if (useRoute().params.uid === props.order.uid) openPopup()
  }
})
const popupOrderDone = async () => {
  store.commit('popup/close')
  store.commit('popup/open', {
    popup: Loader,
  })
  await firebase.function('orderDone', { order: props.order.uid })
  emit('update')
  setTimeout(() => {
    store.commit('popup/close')
  }, 2000)
}
const popupOrderRefused = async () =>
  store.commit('popup/open', {
    popup: RefuseOrder,
    order: props.order.uid,
    update: () => emit('update'),
  })

const popupOrderChange = async () => {
  store.commit('popup/close')
  store.commit('popup/open', {
    popup: Loader,
  })
  await firebase.function('orderChangeNotification', { order: props.order.uid })
  setTimeout(() => {
    store.commit('popup/close')
  }, 2000)
}

const chat = async () => {
  let model = chatModel.value
  if (!model) {
    model = new Chat({
      uid: props.order.uid,
      advertiser: props.order.owner,
      publisher: props.order.publisher,
      createdAt: new Date(),
    })
    model.save()
  }
  store.commit('popup/open', { popup: ChatPopup, chat: model })
}

const refundOrder = () => {
  if (
    confirm(
      'Sicuro di voler rimborsare questo ordine? Questa azione non è reversibile',
    )
  ) {
    store.commit('popup/open', {
      popup: Loader,
    })
    firebase.function('orderRefund', { order: props.order.uid }).then((res) => {
      alert(res)
      emit('update')
      store.commit('popup/close')
    })
  }
}

const deleteOrder = () => {
  if (
    confirm(
      'Io ti avviso, quello che stai facendo è davvero davvero davvero irreparabile...',
    )
  ) {
    store.commit('popup/open', {
      popup: Loader,
    })
    firebase.function('orderDelete', { order: props.order.uid }).then((res) => {
      emit('update')
      store.commit('popup/close')
    })
  }
}

const orderPayment = async () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  window.location.href = await firebase.function('orderPayment', {
    order: props.order.uid,
  })
}

const owner = computed(() => {
  if (props.users)
    return props.users.filter(
      (i: { uid: string }) => i.uid === props.order.owner,
    )[0]
  return { uid: props.order.owner, displayName: null }
})

const publisher = computed(() => {
  if (props.users)
    return props.users.filter(
      (i: { uid: string }) => i.uid === props.order.publisher,
    )[0]
  return { uid: props.order.publisher, displayName: null }
})

const openFeedbackPopup = (rating: any) => {
  store.commit('popup/open', {
    popup: FeedbackListPopup,
    ratings: [rating]
  })
}
</script>
