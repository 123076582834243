import { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue'
import {
  GiftIcon,
  FolderIcon,
  HomeIcon,
  ClipboardListIcon,
  DocumentTextIcon,
  ChatAlt2Icon,
  CurrencyDollarIcon,
  UsersIcon,
  CogIcon,
  SwitchHorizontalIcon,
  CheckCircleIcon,
  ViewBoardsIcon,
  CollectionIcon,
} from '@heroicons/vue/outline'
import { CodeIcon } from '@heroicons/vue/solid'
import User from '../models/user'

export interface NavItem {
  name: string
  text?: string
  href: string
  target?: string
  icon?: FunctionalComponent<HTMLAttributes & VNodeProps, any>
  badge?: string
  showIf?: (user: User | undefined) => void
  preload?: () => void
}

export const adminNav = [
  { name: 'Dashboard', href: '/app/admin', icon: HomeIcon },
  {
    name: 'Products',
    href: '/app/admin/products',
    icon: FolderIcon,
  },
  {
    name: 'Projects',
    href: '/app/admin/projects',
    icon: ViewBoardsIcon,
  },
  {
    name: 'Bundles',
    href: '/app/admin/bundles',
    icon: GiftIcon,
  },
  {
    name: 'sell-presses',
    href: '/app/admin/sell-presses',
    icon: DocumentTextIcon,
  },
  {
    name: 'Orders',
    href: '/app/admin/orders',
    icon: ClipboardListIcon,
  },
  {
    name: 'Chats',
    href: '/app/admin/chats',
    icon: ChatAlt2Icon,
  },
  {
    name: 'Transactions',
    href: '/app/admin/transactions',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Stats',
    href: '/app/admin/stats',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'backlinks-checker',
    href: '/app/admin/blchecker',
    icon: CheckCircleIcon,
  },
  {
    name: 'logs',
    href: '/app/admin/logs',
    icon: CollectionIcon,
  },
  {
    name: 'Users',
    href: '/app/admin/users',
    icon: UsersIcon,
  },
  {
    name: 'Settings',
    href: '/app/admin/settings',
    icon: CogIcon,
  },
  {
    name: 'Import',
    href: '/app/admin/import',
    icon: SwitchHorizontalIcon,
  },
  {
    name: 'Feature Flags',
    href: '/app/admin/features',
    icon: CodeIcon,
  },
  {
    name: 'Development',
    href: '/app/admin/development',
    icon: CodeIcon,
  },
] as NavItem[]

export const softAdminNav = [
  { name: 'Dashboard', href: '/app/soft-admin', icon: HomeIcon },
  {
    name: 'Products',
    href: '/app/soft-admin/products',
    icon: FolderIcon,
  },
  {
    name: 'Orders',
    href: '/app/soft-admin/orders',
    icon: ClipboardListIcon,
  },
  {
    name: 'Chats',
    href: '/app/soft-admin/chats',
    icon: ChatAlt2Icon,
  },
  {
    name: 'backlinks-checker',
    href: '/app/soft-admin/blchecker',
    icon: CheckCircleIcon,
  },
  {
    name: 'Users',
    href: '/app/soft-admin/users',
    icon: UsersIcon,
  },
] as NavItem[]
