<template>
  <Popup>
    <div>
      <h2>{{ user.displayName }}</h2>
      <small>{{ user.uid }}</small>
      <div class="grid grid-cols-2">
        <div>
          <div v-if="!emailVerified" class="text-xs">
            Email not verified
            <a href="#" class="underline" @click.prevent="verifyEmail"
              >Manual Verify</a
            >
          </div>
          <div class="text-sm"><strong>Email:</strong> {{ user.email }}</div>
          <div class="text-sm">
            <strong>Phone:</strong> {{ user.meta?.phone }}
          </div>
          <div v-if="isCurrentAdmin" class="text-sm">
            <strong>Balance:</strong> &euro; {{ (balance || 0).format(2) }}
          </div>
          <div v-if="isCurrentAdmin" class="my-4">
            <strong>Add or subtract balance</strong>
            <div class="flex items-center gap-3">
              <div>
                <label for="adj_balance">Amount</label>
                <FormInput
                  id="adj_balance"
                  v-model="adj.amount"
                  name="adj_balance"
                  type="number"
                />
              </div>
              <div>
                <label for="adj_description">Description</label>
                <FormInput
                  id="adj_description"
                  v-model="adj.description"
                  name="adj_description"
                  type="text"
                />
              </div>
              <div>
                <button
                  v-if="!adj.loading"
                  class="mt-7 btn bg-red-500 text-white"
                  @click="adj_function"
                >
                  Adjust!
                </button>
              </div>
            </div>
          </div>
          <div v-if="isCurrentAdmin" class="flex flex-col my-4">
            <strong>Referrer</strong>
            <div class='w-4/5 flex gap-2 items-center'>
              <FormInput v-model='user.meta.affiliate' type='select'
                       @update:model-value='updateReferrer' name='a'>
                <option value='' :selected='!user.meta.affiliate'>-</option>
                <option v-for='u in users' :key='u.uid' :selected='u.uid === user.meta.affiliate' :value='u.uid'>
                  {{ u.displayName }}
                  ({{ u.email }})
                </option>
              </FormInput>
              <svg :class='user.loading ? "animate-spin text-gray-400" : "invisible"' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
            </div>

          </div>
        </div>
        <div v-if="isCurrentAdmin" class="w-2/3 ml-auto space-y-2">
          <div>
            <button
              class="btn text-white"
              :class="disabled ? 'bg-emerald-500' : 'bg-red-500'"
              @click="switchDisable()"
            >
              {{ disabled ? 'Enable' : 'Disable' }}
            </button>
          </div>
          <div>
            <button
              @click="grantSoftAdmin()"
              class="btn text-white"
              :class="isSoftAdmin ? 'bg-red-500' : 'bg-emerald-500'"
            >
              Grant Soft Admin
            </button>
          </div>
          <div>
            <button
              @click="makeCopywriter()"
              class="btn bg-emerald-500 text-white"
            >
              Grant Copywriter
            </button>
          </div>
        </div>
      </div>
      <hr class="my-2" />
      <h3>Invoicing Accounts</h3>
      <div class="grid lg:grid-cols-3 gap-3">
        <div
          v-for="ic in user.meta?.invoicingAccounts"
          :key="ic"
          class="border p-2"
        >
        <div class="flex justify-end w-full">
          <PencilAltIcon class="text-primary-500 w-6 h-6 cursor-pointer" @click="editInvoicingAccount(ic)" />
        </div>
          <strong v-if="ic.organization">{{ ic.organization }}</strong>
          <strong v-else>{{ ic.firstName }} {{ ic.lastName }}</strong>
          <br />
          <strong>VAT/FC</strong>: {{ ic.vat }} <strong>Address</strong>:
          {{ ic.address }}<br />
          <strong>City</strong>: {{ ic.city }}<br />
          <strong>ZIP</strong>: {{ ic.zip }}<br />
          <strong>State</strong>: {{ ic.state }}<br />
          <strong>Country</strong>: {{ ic.country }}<br />
          <strong>SDI</strong>: {{ ic.sdi }}<br />
          <strong>PEC</strong>: {{ ic.pec }}<br />
        </div>
      </div>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import Popup from './Popup.vue'
import { useI18n } from 'vue-i18n'
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from '@firebase/firestore'
import { firebase } from '../../plugins/firebase'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import FormInput from '../form/FormInput.vue'
import { PencilAltIcon } from '@heroicons/vue/outline'
import InvoicingAccountAdminPopup from './InvoicingAccountAdminPopup.vue'
import User from '../../models/user'

const store = useStore<State>()
const user = store.state.popup.args.user
const users = computed(() => {
  const users = (store.state.popup.args.users ?? []) as User[]
  const sortFn = (u1: User, u2: User) => (u1.displayName || '').toLowerCase().localeCompare((u2.displayName || '').toLowerCase())
  return users.filter(u => u.uid !== user.uid).sort(sortFn)
})

const isCurrentAdmin = useRoute().fullPath.startsWith('/app/admin')
const isAdmin = ref<boolean>(user.firebaseUser.customClaims?.admin ?? false)
const isSoftAdmin = ref<boolean>(
  user.firebaseUser.customClaims?.softAdmin ?? false,
)
const emailVerified = ref<boolean>(user.emailVerified)
const disabled = ref<boolean>(user.meta?.disabled)
const firestore = firebase.getFirestore()
const balance = ref<number>(user.meta?.balance)
const adj = ref<{
  amount: number
  description: string
  loading: boolean
}>({
  amount: 0,
  description: '',
  loading: false,
})
const { t } = useI18n()

const adj_function = () => {
  if (adj.value.amount == 0) {
    alert('Il totale non può essere 0')
    return
  }
  if (adj.value.description == '') {
    alert('La descrizione è obbligatoria')
    return
  }
  adj.value.loading = true
  firebase
    .function('manageBalance', {
      user: user.uid,
      amount: adj.value.amount,
      description: adj.value.description,
    })
    .then((res) => {
      adj.value.loading = false
      if (res) {
        balance.value =
          balance.value + parseFloat(adj.value.amount as unknown as string)
        adj.value.amount = 0
        adj.value.description = ''
      } else alert('insufficient balance')
    })
}

const switchDisable = () => {
  updateDoc(doc(collection(firestore, '/users'), user.uid), {
    disabled: !disabled.value,
  }).then((res) => {
    disabled.value = !disabled.value
    store.state.popup.args.updateUser()
  })
}

const makeCopywriter = async () => {
  const article = (await getDocs(collection(firestore, '/articles'))).docs[0].id
  await updateDoc(doc(collection(firestore, '/articles'), article), {
    owner: user.uid,
  })

  Promise.all(
    (
      await getDocs(
        query(
          collection(firestore, '/users'),
          where('isCopywriter', '==', true),
        ),
      )
    ).docs.map(({ id }) => {
      return updateDoc(doc(collection(firestore, '/users'), id), {
        isCopywriter: false,
      })
    }),
  ).then(() => {
    store.commit('popup/close')
  })

  await updateDoc(doc(collection(firestore, '/users'), user.uid), {
    isCopywriter: true,
  })
}

const grantAdmin = () => {
  firebase
    .function('grantAdmin', { uid: user.uid, admin: !isAdmin.value })
    .then((res) => {
      console.log('grantAdmin', user.uid, !isAdmin.value)
      if (res) isAdmin.value = !isAdmin.value
    })
}

const grantSoftAdmin = () => {
  firebase
    .function('grantSoftAdmin', {
      uid: user.uid,
      softAdmin: !isSoftAdmin.value,
    })
    .then((res) => {
      console.log('grantSoftAdmin', user.uid, !isSoftAdmin.value)
      if (res) isSoftAdmin.value = !isSoftAdmin.value
    })
}

const verifyEmail = () => {
  firebase.function('verifyEmail', { uid: user.uid }).then((res) => {
    console.log('verifyEmail', user.uid, res)
    emailVerified.value = true
  })
}

const editInvoicingAccount = (ic: any) => {
  store.commit('popup/open', {
    popup: InvoicingAccountAdminPopup,
    ic,
    user
  })
}

const updateReferrer = async (referrer: string) => {
  user.loading = true
  const oldReferrer = user.meta.affiliate
  const userInstance = await User.instantiate(user.uid, user)
  try {
    await userInstance.setAffiliate(referrer)
  } catch (e) {
    user.meta.affiliate = oldReferrer
    console.error(e)
  } finally {
    setTimeout(() => {
      user.loading = false
    }, 1000)
  }
}
</script>
