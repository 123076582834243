<template>
  <Heading :text="t('orders')" />
  <div class="my-4">
    <PillTabs v-model="currentFilter" i18n :values="filters" />
  </div>
  <div
    v-if="currentFilter == 0"
    :class="'grid xl:grid-cols-4 gap-3 my-4'"
  >
    <InfoBox v-for="f of filters" :key="f" :color="filters_colors[f]">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t(f) }}</div>
        <div class="font-bold text-3xl">
          {{ items?.filter((i) => i.status === f || f === 'all').length }}
        </div>
      </div>
    </InfoBox>
  </div>
  <div
    class="space-y-2 lg:space-y-0 lg:flex items-center justify-between gap-2"
  >
    <input
      v-model="filterByName"
      type="text"
      placeholder="Search by UID..."
      class="lg:my-4 flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
    />
    <FormInput v-model="sortBy" name="sortBy" type="select">
      <option value="date">Date</option>
      <option value="amount">Amount</option></FormInput
    >
    <FormInput v-model="sortOrder" name="sortOrder" type="select">
      <option value="asc">ASC</option>
      <option value="desc">DESC</option></FormInput
    >
    <button v-if="isAdmin" class="btn gradient" @click="openExportPopup">
      Export
    </button>
  </div>
  <div
    v-for="order in filteredOrders?.slice(
      (pagination.current - 1) * pagination.resultsPerPage,
      (pagination.current - 1) * pagination.resultsPerPage +
        pagination.resultsPerPage,
    )"
    :key="order.uid"
  >
    <OrderItem :users="users as any" :order="order" @update="getOrders" />
  </div>
  <div v-if="!filteredOrders.length">No orders to show.</div>
  <Pagination
    :pagination="pagination"
    :handle-next="next"
    :handle-prev="prev"
  />
</template>
<script lang="ts" setup>
import OrderItem from '../../components/OrderItem.vue'
import Heading from '../../components/Heading.vue'
import { onMounted, ref, watch } from 'vue'
import Order from '../../models/order'
import PillTabs from '../../components/PillTabs.vue'
import { useI18n } from 'vue-i18n'
import InfoBox from '../../components/InfoBox.vue'
import User from '../../models/user'
import { orderBy } from '@firebase/firestore'
import store from '../../store'
import LoaderVue from '../../components/Loader.vue'
import Pagination from '../../components/Pagination.vue'
import FormInput from '../../components/form/FormInput.vue'
import { useRoute } from 'vue-router'
import ExportOrdersVue from '../../components/popups/ExportOrders.vue'

const { t } = useI18n()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const items = ref<Order[]>()
const users = ref<User[]>([])

const productUid = useRoute().query.productUid as string

const getOrders = () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  Order.all([orderBy('createdAt', 'desc')]).then((orders) => {
    items.value = (orders as Order[]).filter((o) => !productUid || o.product === productUid)
    sortOrFilter()
    store.commit('popup/close')
  })
}
onMounted(getOrders)

const filters = [
  'all',
  'pending',
  'paid',
  'to-take-wip',
  'work-in-progress',
  'need-review',
  'completed',
  'refunded',
  'canceled',
]
const currentFilter = ref<number>(0)
const filterByName = ref<string>((useRoute().query.search as string) ?? '')
const sortBy = ref<'date' | 'amount'>('date')
const sortOrder = ref<'asc' | 'desc'>('desc')
const filteredOrders = ref<Order[]>([])
const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})

const filters_colors = <any>{
  'all': 'bg-gray-200',
  'pending': 'bg-yellow-500',
  'paid': 'bg-green-500',
  'work-in-progress': 'bg-blue-500',
  'need-review': 'bg-purple-500',
  'completed': 'bg-indigo-500',
  'refunded': 'bg-orange-500',
  'canceled': 'bg-red-500',
}

User.allLight().then((res) => {
  users.value = res as User[]
})

const sortOrFilter = () => {
  let _orders = [...(items.value as Order[])].sort((a: Order, b: Order) => {
    if (sortBy.value === 'date' && sortOrder.value === 'desc')
      return a.createdAt.getTime() > b.createdAt.getTime() ? -1 : 1
    if (sortBy.value === 'date' && sortOrder.value === 'asc')
      return a.createdAt.getTime() > b.createdAt.getTime() ? 1 : -1
    if (sortBy.value === 'amount' && sortOrder.value === 'desc')
      return a.price > b.price ? -1 : 1
    if (sortBy.value === 'amount' && sortOrder.value === 'asc')
      return a.price > b.price ? 1 : -1
    return 1
  })

  if (filters[currentFilter.value] === 'all') _orders = [..._orders]
  else {
    _orders = _orders.filter((i: Order) => {
      return (
        (filters[currentFilter.value] === 'to-take-wip' &&
          i.status === 'paid' &&
          i.advertiserInfo) || 
          (filters[currentFilter.value] === 'paid' &&
          i.status === 'paid' &&
          !i.advertiserInfo) ||
        i.status === filters[currentFilter.value]
      )
    })
  }

  if (filterByName.value !== '')
    _orders = _orders.filter((i) => {
      return i.uid?.match(new RegExp(filterByName.value, 'i'))
    })

  filteredOrders.value = _orders
  pagination.value.total = filteredOrders.value.length
  pagination.value.totalPages = Math.ceil(
    filteredOrders.value.length / pagination.value.resultsPerPage,
  )
}

watch([filterByName, currentFilter, sortBy, sortOrder], () => {
  sortOrFilter()
})

const next = async () => {
  pagination.value.current++
}

const prev = async () => {
  pagination.value.current--
}

const openExportPopup = () => {
  store.commit('popup/open', {
    popup: ExportOrdersVue,
  })
}
</script>
