<template>
  <div class="grid xl:grid-cols-4 gap-3 my-4">
    <InfoBox color="bg-blue-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('approved') }}</div>
        <div class="font-bold text-3xl">{{ products.length }}</div>
      </div>
    </InfoBox>
    <InfoBox color="bg-green-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('to-approve') }}</div>
        <div class="font-bold text-3xl">
          {{ products.filter((i) => !i.approved).length }}
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-red-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('pending-cron') }}</div>
        <div class="font-bold text-3xl">
          {{ products?.filter((i) => !i.updated).length }}
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-orange-500">
      <div class="text-center">
        <div class="text-sm uppercase">errored</div>
        <div class="font-bold text-3xl">
          {{ products?.filter((i) => i.type !== 'social' && i.updated && !i.updates.ahrefs).length }}
        </div>
      </div>
    </InfoBox>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import InfoBox from '../../components/InfoBox.vue'
import Product from '../../models/product';

const products = ref<Product[]>([])
const { t } = useI18n()
Product.all().then((r) => products.value = r as Product[])
</script>
../InfoBox.vue