<template>
  <div v-if="products.length">
    <ProductItem
      v-for="product in products.slice(
        (pagination.current - 1) * pagination.resultsPerPage,
        (pagination.current - 1) * pagination.resultsPerPage +
          pagination.resultsPerPage,
      )"
      :key="product.uid"
      :product="product"
      :users="props.users"
      @update="update"
    />
    <Pagination
      :pagination="pagination"
      :handle-next="next"
      :handle-prev="prev"
    />
  </div>
  <div v-else>No products to show.</div>
</template>
<script setup lang="ts">
import {
  QueryConstraint,
  where,
} from '@firebase/firestore'
import { ref } from 'vue'
import Product from '../../models/product'
import ProductItem from '../ProductItem.vue'
import Pagination from '../Pagination.vue'
import store from '../../store'
import LoaderVue from '../Loader.vue'

const props = defineProps<{
  users: any
}>()

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})
const products = ref<Product[]>([])

const common_query = [
  where('updated', '==', false),
]

Product.count(common_query).then((total) => {
  pagination.value.total = total
  pagination.value.totalPages = Math.ceil(
    total / pagination.value.resultsPerPage,
  )
})

const next = async () => {
  pagination.value.current++
}

const prev = async () => {
  pagination.value.current--
}

const fetch = async (query: QueryConstraint[] = []) => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  const prods = (await Product.all([...query])) as Product[]
  products.value = (prods as Product[]).filter(i => !i.rejected && !i.approved)
  store.commit('popup/close')
}

const update = async () => {
  fetch([...common_query])
}
update()
</script>
