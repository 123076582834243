<template>
  <ProductsStats />
  <div v-if="products.length">
    <ProductItem
      v-for="product in products"
      :key="product.uid"
      :product="product"
      :users="props.users"
      @update="update"
    />
    <Pagination
      :pagination="pagination"
      :handle-next="next"
      :handle-prev="prev"
    />
  </div>
  <div v-else>No products to show.</div>
</template>
<script setup lang="ts">
import {
  limit,
  orderBy,
  QueryConstraint,
  startAfter,
} from '@firebase/firestore'
import { ref } from 'vue'
import Product from '../../models/product'
import ProductItem from '../ProductItem.vue'
import Pagination from '../Pagination.vue'
import store from '../../store'
import LoaderVue from '../Loader.vue'
import ProductsStats from './ProductsStats.vue'

const props = defineProps<{
  users: any
}>()

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})
const last = ref<any>(undefined)
const products = ref<Product[]>([])

Product.count().then((total) => {
  pagination.value.total = total
  pagination.value.totalPages = Math.ceil(
    total / pagination.value.resultsPerPage,
  )
})

const next = async () => {
  await fetch([
    orderBy('createdAt', 'desc'),
    ...(last.value ? [startAfter(last.value)] : []),
  ])
  pagination.value.current++
}

const prev = async () => {
  await fetch([
    orderBy('createdAt', 'asc'),
    ...(last.value ? [startAfter(last.value)] : []),
  ])
  pagination.value.current--
}

const fetch = async (query: QueryConstraint[] = []) => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  const prods = (await Product.all([
    ...query,
    limit(pagination.value.resultsPerPage),
  ])) as Product[]
  if (!prods.length) {
    store.commit('popup/close')
    return
  }
  last.value = prods[prods.length - 1].createdAt
  products.value = prods
  store.commit('popup/close')
}

const update = async () => {
  fetch([orderBy('createdAt', 'desc')])
}
update()
</script>
