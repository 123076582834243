<template>
  <Heading :text="t('stats')" />
  <div
    v-if="loading"
    class="flex justify-center items-center absolute inset-0 bg-white bg-opacity-75"
  >
    <svg
      class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-500 text-lg"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    Loading...
  </div>
  <div v-else class="flex flex-col">
    <div class="flex items-center justify-between">
      <div>
        <div class="flex items-center justify-between gap-3">
          <div>
            {{ t('from') }}:
            <FormInput v-model="filterDate.from" name="date_from" type="date" />
          </div>
          <div>
            {{ t('to') }}:
            <FormInput v-model="filterDate.to" name="date_to" type="date" />
          </div>
        </div>
      </div>
      <div>
        <button class="btn primary" @click="usersToTransactions()">
          {{ t('filter') }}
        </button>
        <button
          class="btn secondary"
          @click="
            filterDate = { to: undefined, from: undefined }
          "
        >
          {{ t('reset') }}
        </button>
      </div>
    </div>
    <TxStats
      v-if="transactions"
      :key="transactions?.length"
      :transactions="transactions"
      :users="users"
    />
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import Heading from '../../components/Heading.vue'
import User, { Meta } from '../../models/user'
import { useFirebase } from '../../plugins/firebase'
import { useI18n } from 'vue-i18n'
import TxStats from '../../components/admin/TxStats.vue'
import { useRoute } from 'vue-router'
import FormInput from '../../components/form/FormInput.vue'

const { t } = useI18n()
const users = ref<User[]>()
const transactions = ref<Meta['transactions'] | undefined>()
const filteredTransactions = ref<any>()
const loading = ref<boolean>(true)
const filter = ref(0)
const hideCanceled = ref<boolean>(false)
const toDefault = new Date(new Date().setDate(new Date().getDate() - 7))
const toDefaultString =
  toDefault.getFullYear() +
  '-' +
  ('0' + (toDefault.getMonth() + 1)).slice(-2) +
  '-' +
  ('0' + toDefault.getDate()).slice(-2)
const fromDefaultString =
  new Date().getFullYear() +
  '-' +
  ('0' + (new Date().getMonth() + 1)).slice(-2) +
  '-' +
  ('0' + new Date().getDate()).slice(-2)
const filterDate = ref<{
  from?: string
  to?: string
}>({
  to: fromDefaultString,
  from: toDefaultString,
})
const filterName = ref<string>()
const bulkTxn = ref<string[]>([])
const route = useRoute()
const filterByUser = ref<string | null>((route.query.user as string) || null)

onMounted(() => {
  loading.value = true
  User.all().then((res) => {
    users.value = res as User[]
    usersToTransactions()
    loading.value = false
  })
})

const usersToTransactions = () => {
  transactions.value = undefined
  let _transactions = []
  for (let user of users.value ?? []) {
    for (let trans of user.meta?.transactions ?? []) {
      _transactions.push({ ...trans, user: user })
    }
  }

  if (filterDate.value.from || filterDate.value.to) {
    _transactions = _transactions.filter((i) => {
      if (filterDate.value.from && !filterDate.value.to)
        return (
          i.createdAt.getTime() >=
          new Date(new Date(filterDate.value.from).setHours(0, 0, 0)).getTime()
        )
      else if (!filterDate.value.from && filterDate.value.to) {
        return (
          i.createdAt.getTime() <=
          new Date(new Date(filterDate.value.to).setHours(23, 59, 59)).getTime()
        )
      } else if (filterDate.value.from && filterDate.value.to)
        return (
          i.createdAt.getTime() >=
            new Date(
              new Date(filterDate.value.from).setHours(0, 0, 0),
            ).getTime() &&
          i.createdAt.getTime() <=
            new Date(
              new Date(filterDate.value.to).setHours(23, 59, 59),
            ).getTime()
        )
    })
  }
  transactions.value = _transactions
  filterTransactions()
}

const filterTransactions = () => {
  if (!transactions.value) return
  let _transactions = [...(transactions.value as Meta['transactions'])]

  filteredTransactions.value = _transactions
}
</script>
