<template>
  <div class="flex justify-center">
    <VueRecaptcha
      v-if="!isDev"
      sitekey="6LfLeu8nAAAAAOI7JHoynly_x7zpKuciJWECwj-B"
      :load-recaptcha-script="true"
      @verify="handleSuccess"
      @expired="handleExpireError"
      @error="handleExpireError"
    ></VueRecaptcha>
  </div>
</template>
<script setup lang="ts">
import { VueRecaptcha } from 'vue-recaptcha'

const isDev = import.meta.env.DEV ? true : false
defineProps({
  handleSuccess: {
    type: Function,
    default: void 0
  },
})

const handleExpireError = () => {
  //console.log("expired or error")
  alert('ReCaptcha Expired')
  window.location.reload()
}
</script>
