<template>
  <div
    ref="root"
    class="fixed top-0 left-0 z-[100] w-full h-screen bg-white bg-opacity-75 flex items-center justify-center"
  >
    <div>
      <img id="image" src="/assets/favicon.svg" width="100" />
      <div v-if="text" class="text-center text-md font-bold mt-2">
        {{ t(text) }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import store from '../store'

const root = ref<HTMLDivElement>()
const text = store.state.popup.args?.text ?? null

const { t } = useI18n()
</script>
<style scoped>
#image {
  transform: scale(1);
  animation: pulse 0.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(0.95);
  }
}
</style>
