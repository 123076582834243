import Dashboard from '../pages/admin/Dashboard.vue'
import Products from '../pages/admin/Products.vue'
import ProductAddEdit from '../pages/admin/ProductAddEdit.vue'
import Orders from '../pages/admin/Orders.vue'
import Settings from '../pages/admin/Settings.vue'
import Transactions from '../pages/admin/Transactions.vue'
import Stats from '../pages/admin/Stats.vue'
import Users from '../pages/admin/Users.vue'
import Development from '../pages/admin/Development.vue'
import Bundles from '../pages/admin/Bundles.vue'
import SellArticles from '../pages/publisher/SellArticles.vue'
import SellPresses from '../pages/admin/SellPresses.vue'
import Chats from '../pages/admin/Chats.vue'
import BacklinkChecker from '../pages/admin/BacklinkChecker.vue'
import Logs from '../pages/admin/Logs.vue'
import Import from '../pages/publisher/Import.vue'
import Projects from '../pages/advertiser/Projects.vue'
import ProjectsList from '../pages/advertiser/projects/ProjectsList.vue'
import ProjectsAddEdit from '../pages/advertiser/projects/ProjectsAddEdit.vue'
import CampaignAddEdit from '../pages/advertiser/projects/CampaignAddEdit.vue'
import CampaignView from '../pages/advertiser/projects/CampaignView.vue'
import { RouterView } from 'vue-router'
import FeatureFlags from '../pages/admin/FeatureFlags.vue'

export default [
  {
    path: 'admin',
    component: Dashboard,
  },
  {
    path: 'admin/products',
    component: Products,
  },
  {
    path: 'admin/products/:id/edit',
    component: ProductAddEdit,
  },
  {
    path: 'admin/orders',
    component: Orders,
  },
  {
    path: 'admin/chats',
    component: Chats,
  },
  {
    path: 'admin/users',
    component: Users,
  },
  {
    path: 'admin/users/:uid',
    component: Users,
  },
  {
    path: 'admin/transactions',
    component: Transactions,
  },
  {
    path: 'admin/stats',
    component: Stats,
  },
  {
    path: 'admin/settings',
    component: Settings,
  },
  {
    path: 'admin/sell-articles',
    component: SellArticles,
  },
  {
    path: 'admin/sell-presses',
    component: SellPresses,
  },
  {
    path: 'admin/features',
    component: FeatureFlags,
  },
  {
    path: 'admin/development',
    component: Development,
  },
  {
    path: 'admin/blchecker',
    component: BacklinkChecker,
  },
  {
    path: 'admin/logs',
    component: Logs,
  },
  {
    path: 'admin/bundles',
    component: Bundles,
  },
  {
    path: 'admin/import',
    component: Import,
  },
  {
    path: 'admin/projects',
    component: Projects,
    children: [
      { path: '', component: ProjectsList },
      { path: 'create', component: ProjectsAddEdit },
      { path: ':project/edit', component: ProjectsAddEdit },
      { path: ':project/campaigns/create', component: CampaignAddEdit },
      { path: ':project/campaigns/:campaign', component: CampaignView },
      { path: ':project/campaigns/:campaign/edit', component: CampaignAddEdit },
    ],
  },
]
