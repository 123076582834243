<template>
  <Heading text="Users" />
  <div v-if="users">
    <div class="flex items-center justify-between">
      <div>
        <PillTabs v-model="currentFilter" :values="filters" i18n />
      </div>
      <button
        v-if="isAdmin"
        class="btn bg-emerald-500 small text-white"
        @click="exportUsersToCSV"
      >
        {{ t('export-all-to-csv') }}
      </button>
    </div>
    <div v-if="currentFilter == 0" class="grid xl:grid-cols-4 gap-3 my-4">
      <InfoBox color="bg-blue-500">
        <div class="text-center">
          <div class="text-sm uppercase">{{ t('all') }}</div>
          <div class="font-bold text-3xl">{{ users.length }}</div>
        </div>
      </InfoBox>
      <InfoBox color="bg-green-500">
        <div class="text-center">
          <div class="text-sm uppercase">{{ t('no-invoicing-accounts') }}</div>
          <div class="font-bold text-3xl">
            {{
              users.filter((i) => (i.meta?.invoicingAccounts ?? []).length < 1)
                .length
            }}
          </div>
        </div>
      </InfoBox>
      <InfoBox color="bg-purple-500">
        <div class="text-center">
          <div class="text-sm uppercase">{{ t('zero-balance') }}</div>
          <div class="font-bold text-3xl">
            {{ users.filter((i) => (i.meta?.balance ?? 0) < 1).length }}
          </div>
        </div>
      </InfoBox>
      <InfoBox color="bg-red-500">
        <div class="text-center">
          <div class="text-sm uppercase">{{ t('email-not-verified') }}</div>
          <div class="font-bold text-3xl">
            {{ users.filter((i) => !i.emailVerified).length }}
          </div>
        </div>
      </InfoBox>
    </div>
    <div class="flex items-center justify-between gap-2">
      <input
        v-model="filterByName"
        type="text"
        placeholder="Search by UID, Name, email or phone..."
        class="my-4 flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
      />
      <FormInput v-model="sortBy" name="sortBy" type="select">
        <option value="date">Date</option>
        <option value="balance">Balance</option></FormInput
      >
      <FormInput v-model="sortOrder" name="sortOrder" type="select">
        <option value="asc">ASC</option>
        <option value="desc">DESC</option></FormInput
      >
    </div>
    <div>
      <template v-if="route.params.uid">
        <UserItem
          v-for="user of filteredUsers?.filter(
            (i) => i.uid === route.params.uid,
          )"
          :key="user.uid"
          :user="user"
          :users="
            users.map((i) => ({
              ...i,
              uid: i.uid,
              email: i.email,
              affiliate: i.meta?.affiliate,
            }))
          "
          :affiliates="
            users.filter((i) => i.meta?.affiliate === user.uid).length
          "
          @update="update"
        />
      </template>
      <template v-else>
        <UserItem
          v-for="user of filteredUsers?.slice(
            (pagination.current - 1) * pagination.resultsPerPage,
            (pagination.current - 1) * pagination.resultsPerPage +
              pagination.resultsPerPage,
          )"
          :key="user.uid"
          :user="user"
          :users="
            users.map((i) => ({
              ...i,
              uid: i.uid,
              email: i.email,
              affiliate: i.meta?.affiliate,
            }))
          "
          :affiliates="
            users.filter((i) => i.meta?.affiliate === user.uid).length
          "
          @update="update"
        />
      </template>
    </div>
    <Pagination
      :pagination="pagination"
      :handle-next="next"
      :handle-prev="prev"
    />
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue'
import User from '../../models/user'
import UserItem from '../../components/admin/UserItem.vue'
import Heading from '../../components/Heading.vue'
import PillTabs from '../../components/PillTabs.vue'
import InfoBox from '../../components/InfoBox.vue'
import { useI18n } from 'vue-i18n'
import store from '../../store'
import LoaderVue from '../../components/Loader.vue'
import Pagination from '../../components/Pagination.vue'
import FormInput from '../../components/form/FormInput.vue'
import { useRoute } from 'vue-router'
import { useFirebase } from '../../plugins/firebase'

const firebase = useFirebase()
const route = useRoute()
const isAdmin = route.fullPath.startsWith('/app/admin')
const filters = ref<string[]>([
  'all',
  'no-invoicing-accounts',
  'zero-balance',
  'email-not-verified',
  'affiliates',
])
const currentFilter = ref<number>(0)
const filterByName = ref<string>()

const users = ref<User[]>()
const filteredUsers = ref<User[]>()
const { t } = useI18n()

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})

const sortBy = ref<'date' | 'balance'>('date')
const sortOrder = ref<'asc' | 'desc'>('asc')

const next = async () => {
  pagination.value.current++
}

const prev = async () => {
  pagination.value.current--
}

onMounted(() => {
  update()
  filteredUsers.value = users.value
})

const update = () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  User.all().then((res) => {
    users.value = res as User[]
    sortOrFilter()
    store.commit('popup/close')
  })
}

watch([filterByName, currentFilter], () => {
  sortOrFilter()
})

const sortOrFilter = () => {
  let _users = [...(users.value as User[])].sort((a: User, b: User) => {
    if (sortBy.value === 'date' && sortOrder.value === 'desc')
      return new Date(a.metadata?.creationTime as string).getTime() >
        new Date(b.metadata?.creationTime as string).getTime()
        ? -1
        : 1
    if (sortBy.value === 'date' && sortOrder.value === 'asc')
      return new Date(a.metadata?.creationTime as string).getTime() >
        new Date(b.metadata?.creationTime as string).getTime()
        ? 1
        : -1
    if (sortBy.value === 'balance' && sortOrder.value === 'desc')
      return (a.meta?.balance || 0) > (b.meta?.balance || 0) ? -1 : 1
    if (sortBy.value === 'balance' && sortOrder.value === 'asc')
      return (a.meta?.balance || 0) > (b.meta?.balance || 0) ? 1 : -1
    return 1
  })

  if (filters.value[currentFilter.value] === 'all')
    filteredUsers.value = users.value
  if (filters.value[currentFilter.value] === 'no-invoicing-accounts')
    _users = _users.filter((i) => (i.meta?.invoicingAccounts ?? []).length < 1)
  if (filters.value[currentFilter.value] === 'zero-balance')
    _users = _users.filter((i) => (i.meta?.balance ?? 0) < 1)
  if (filters.value[currentFilter.value] === 'email-not-verified')
    _users = _users.filter((i) => !i.emailVerified)
  if (filters.value[currentFilter.value] === 'affiliates')
    _users = _users.filter((i) => i.meta?.affiliate)

  if (filterByName.value !== '')
    _users = _users.filter((i) => {
      return (
        i.displayName?.match(new RegExp(filterByName.value as string, 'i')) ||
        i.email?.match(new RegExp(filterByName.value as string, 'i')) ||
        i.meta?.phone?.match(new RegExp(filterByName.value as string, 'i')) ||
        i.uid === filterByName.value
      )
    })
  filteredUsers.value = _users
  pagination.value.total = filteredUsers.value.length
  pagination.value.totalPages = Math.ceil(
    filteredUsers.value.length / pagination.value.resultsPerPage,
  )
}

const exportUsersToCSV = async () => {
  try {
    store.commit('popup/open', { popup: LoaderVue })
    const res = await firebase.function('exportUsersToCSV', {}, {
      timeout: 540000
    })
    const tmp_a = document.createElement('a')
    tmp_a.href = window.URL.createObjectURL(
      new Blob([(res as string[]).join('\n')]),
    )
    tmp_a.download = 'export-users.tsv'
    tmp_a.click()
    store.commit('popup/close')
  } catch (e) {
    console.error(e)
    alert('fai screenshot console')
  }
}

watch([sortBy, sortOrder], () => {
  sortOrFilter()
})
</script>
