<template>
  <div
    class="group py-10 border-b last:border-none border-gray-200 even:bg-gray-50 hover:bg-primary-50 px-10 flex flex-col lg:flex-row gap-6 md:gap-0 lg:items-center space-x-6"
  >
    <div class="flex flex-col flex-grow">
      <div class="flex flex-grow items-center gap-6">
        <div class="flex-auto flex flex-col">
          <div class="flex flex-col gap-4">
            <h4 class="font-medium text-gray-900 flex">
              {{ user.displayName }}
            </h4>
            <small>{{ user.uid }}</small>
            <div class="flex text-gray-600 items-center justify-between">
              <div class="flex flex-col">
                <div>
                  <strong>Creation date</strong>:
                  {{ user.metadata?.creationTime }}
                </div>
                <div>
                  <strong>Email verified</strong>: {{ user.emailVerified }}
                </div>
                <div><strong>Email</strong>: {{ user.email }}</div>
                <div><strong>Phone</strong>: {{ user.meta?.phone }}</div>
                <div>
                  <strong>Affiliates</strong>: {{ props.affiliates }} (€
                  {{
                    user.meta?.transactions
                      .filter((i) => i.type === 'affiliate')
                      .map((i) => i.amount)
                      .reduce((a: number, b: number) => a + b, 0)
                      .format(2)
                  }})
                </div>
                <div v-if="user.meta?.affiliate">
                  <strong>Affiliate of</strong>:
                  {{
                    props.users.find((i: any) => i.uid === user.meta?.affiliate)
                      .email
                  }}
                </div>
                <div>
                  <a
                    href="#"
                    class="underline"
                    @click.prevent="loginWithUserToken(user.uid)"
                    >Login with user</a
                  >
                  -
                  <a
                    href="#"
                    class="underline"
                    @click.prevent="loginWithUserToken(user.uid, true)"
                    >Login with user (ONLY DEV)</a
                  ><br />
                  <a
                    href="#"
                    class="underline"
                    @click.prevent="openRatingsPopup(user.uid)"
                    >Load Reviews</a
                  >
                </div>
              </div>
              <div class="flex flex-col">
                <div v-if="isAdmin">
                  <strong>Balance</strong>: {{ user.meta?.balance?.format() }} /
                  {{ pendingTransactions.reduce((a, b) => a + b.amount, 0)?.format() }}
                </div>
                <div v-if="isAdmin">
                  <strong>Transactions</strong>:
                  {{ user.meta?.transactions.length }}
                </div>
                <div>
                  <strong>Invoicing Accounts</strong>:
                  {{ user.meta?.invoicingAccounts.length }}
                </div>
                <div>
                  <strong>Products count</strong>: {{ pcount }}
                  <RouterLink
                    v-if="pcount > 0"
                    class="block underline"
                    target="_BLANK"
                    :to="'/app/advertiser/products/owner/' + user.uid"
                    >Products List</RouterLink
                  >
                  <RouterLink
                    class="block underline"
                    target="_BLANK"
                    :to="'/app/admin/transactions?user=' + user.uid"
                    >Transactions List</RouterLink
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            (user.meta?.orderFee || user.meta?.affiliatePercentage) && !editing
          "
          class="flex flex-col gap-2 text-xl font-bold whitespace-nowrap"
        >
          <div v-if="user.meta?.orderFee" class="text-base">
            {{ t('order-fee') }}
          </div>
          <div v-if="user.meta?.orderFee">
            {{ (user.meta.orderFee * 100)?.format(2) }} %
          </div>
          <div v-if="user.meta?.affiliatePercentage" class="text-base">
            {{ t('affiliate-percentage') }}
          </div>
          <div v-if="user.meta?.affiliatePercentage">
            {{ (user.meta.affiliatePercentage * 100)?.format(2) }} %
          </div>
        </div>
        <div v-else-if="editing">
          <label>{{ t('order-fee') }}</label>
          <FormInput
            v-model="orderFee"
            name="order-fee"
            type="number"
            after="%"
            step="0.01"
            :disabled="loading"
            class="disabled:bg-gray-50"
          />
          <small class="text-xs text-gray-600">
            {{ t('empty-for-default-fee') }}
          </small>
          <br />
          <label>{{ t('affiliate-percentage') }}</label>
          <FormInput
            v-model="affiliatePercentage"
            name="affiliate-percentage"
            type="number"
            after="%"
            step="0.01"
            :disabled="loading"
            class="disabled:bg-gray-50"
          />
          <small class="text-xs text-gray-600">
            {{ t('empty-for-default-affiliate-percentage') }}
          </small>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-evenly gap-2">
      <button
        class="gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="openPopup"
      >
        <EyeIcon class="w-4 h-4" />
      </button>
      <button
        v-if="!editing && isAdmin"
        class="bg-primary-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="editing = true"
      >
        <PencilIcon class="h-4 w-4" />
      </button>
      <button
        v-else-if="isAdmin"
        class="text-emerald-500 disabled:opacity-50"
        :disabled="loading"
        @click="save"
      >
        <CheckCircleIcon class="w-8 h-8" />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { CheckCircleIcon, EyeIcon, PencilIcon } from '@heroicons/vue/solid'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, RouterLink } from 'vue-router'
import Product from '../../models/product'
import User from '../../models/user'
import { useFirebase } from '../../plugins/firebase'
import store from '../../store'
import FormInput from '../form/FormInput.vue'
import Loader from '../Loader.vue'
import FeedbackListPopup from '../popups/FeedbackListPopup.vue'
import UserPopupVue from '../popups/UserPopup.vue'

const { t } = useI18n()

const firebase = useFirebase()
const props = defineProps<{
  user: User
  users: any[]
  affiliates: number
}>()
const pendingTransactions = computed(() => {
  return (props.user.meta?.transactions || []).filter((t) => t.status === 'pending')
})
const emit = defineEmits(['update'])
const pcount = ref<number>(0)
const isAdmin = useRoute().fullPath.startsWith('/app/admin')

const orderFee = ref(Number(props.user.meta?.orderFee) * 100)
const affiliatePercentage = ref(
  Number(props.user.meta?.affiliatePercentage) * 100,
)
const editing = ref(false)
const loading = ref(false)

const save = async () => {
  loading.value = true
  await props.user.setOrderFee(Number(orderFee.value) / 100)
  await props.user.setAffiliatePercentage(
    Number(affiliatePercentage.value) / 100,
  )
  loading.value = false
  editing.value = false
  emit('update')
}

const openPopup = () => {
  store.commit('popup/open', {
    popup: UserPopupVue,
    user: props.user,
    users: props.users,
    updateUser: () => emit('update'),
  })
}

(() => {
  if (useRoute().params.uid) {
    if (useRoute().params.uid === props.user.uid) openPopup()
  }

  Product.all().then((res) => {
    pcount.value = (res as Product[]).filter(
      (i) => i.owner === props.user.uid,
    ).length
  })
})

const loginWithUserToken = async (uid: string, dev?: boolean) => {
  store.commit('popup/open', {
    popup: Loader,
  })
  const result: any = await firebase.function('generateUserToken', { uid })
  store.commit('popup/close')
  if (result) {
    if (!dev)
      window.open('https://app.rankister.com/?cToken=' + result, '_BLANK')
    else window.open('http://localhost:3000/?cToken=' + result, '_BLANK')
  }
}

const openRatingsPopup = async (uid: string) => {
  store.commit('popup/open', {
    popup: FeedbackListPopup,
    uid,
  })
}
</script>
