<template>
  <Popup>
    <div class="lg:flex flex-grow items-center gap-6">
      <div class="flex-auto flex flex-col">
        <div>
          <h4 class="font-medium text-gray-900 flex">
            {{ product.name }}
            <div class="flex items-center gap-1 mx-2">
              <a
                v-for="t of types"
                :key="t"
                target="_BLANK"
                :href="'https://' + ((product as any)[t][0])"
              >
                <img :src="`/assets/icons/${t}.svg`" width="16" height="16" />
              </a>
            </div>
          </h4>
          <p class="mt-2 text-sm text-gray-600 max-w-2xl">
            {{ product.description }}
          </p>
        </div>
      </div>
      <div class="flex text-xl font-bold whitespace-nowrap">
        {{ Number(product.price).format() }} €
      </div>
    </div>
    <table class="w-full mt-4">
      <tr v-if="product.website[0]" class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('domain-authority') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ product.metrics.da }}
        </td>
      </tr>
      <tr v-if="product.website[0]" class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('domain-rating') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ product.metrics.dr }}
        </td>
      </tr>
      <tr
        v-if="product.website[0] && product.country === 'it'"
        class="hover:static-gradient-transparent"
      >
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('zoom-authority') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ product.metrics.za }}
        </td>
      </tr>
      <!--tr v-if="product.website[0]">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">{{ t('pa') }}</td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">{{ product.metrics.pa }}</td>
      </tr-->
      <tr v-if="product.website[0]" class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('google-news') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ t(product.google_news ? 'yes' : 'no') }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('backlinks') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ product.metrics.backlinks?.total }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('dofollow') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ product.metrics.backlinks?.dofollow }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('nofollow') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ product.metrics.backlinks?.nofollow }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('edu') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ product.metrics.backlinks?.edu }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-r px-2 py-1">
          {{ t('gov') }}
        </td>
        <td class="ml-2 text-gray-700 px-2 py-1">
          {{ product.metrics.backlinks?.gov }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('accepts-gambling') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ t(product.accepts.gambling ? 'yes' : 'no') }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('accepts-cbd') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ t(product.accepts.cbd ? 'yes' : 'no') }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('accepts-adult') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ t(product.accepts.adult ? 'yes' : 'no') }}
        </td>
      </tr>
      <tr class="hover:static-gradient-transparent">
        <td class="font-medium text-gray-900 border-b border-r px-2 py-1">
          {{ t('accepts-trading') }}
        </td>
        <td class="ml-2 text-gray-700 border-b px-2 py-1">
          {{ t(product.accepts.trading ? 'yes' : 'no') }}
        </td>
      </tr>
    </table>
    <div class="flex justify-end mt-6">
      <button
        v-if="!isPublisher && !isAdmin"
        class="flex items-center gap-1 btn gradient"
        @click.prevent="addToCart(product as Product)"
      >
        <PlusIcon class="h-5 w-6" />
        {{ t('add-to-cart') }}
      </button>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Product from '../../models/product'
import { State } from '../../store'
import Popup from './Popup.vue'
import { PlusIcon } from '@heroicons/vue/outline'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore<State>()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isPublisher = store.state.AccountType.type === 'publisher'

const productImage = store.state.popup.args.productImage as string
const product = store.state.popup.args.product as Product
const types = [
  'website',
  'facebook',
  'instagram',
  'youtube',
  'tiktok',
  'reddit',
  'telegram',
].filter((type) => (product as unknown as Record<string, []>)[type].length)

const router = useRouter()
const addToCart = (product: Product) => {
  store.commit('cart/add', product)
  store.commit('popup/close')
  router.push({ path: '/app/advertiser/cart' })
}
</script>
