<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <h3 class="my-2">{{ t('recently-added-services') }}</h3>
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <div v-if="!products.length" class="flex justify-center my-4">
            <Loading />
          </div>
          <table v-else class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('type') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('name') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('price') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="product in products"
                :key="product.name"
                :class="[
                  ...(product.adminFeatured
                    ? ['bg-secondary-400']
                    : ['bg-white even:bg-gray-50']),
                  ...(product.adminFeatured
                    ? ['text-white font-bold']
                    : ['text-gray-500']),
                ]"
              >
                <td
                  :class="['px-6 py-4 whitespace-nowrap text-sm font-medium']"
                >
                  <div class="flex gap-2">
                    <img
                      v-for="ty of getTypes(product)"
                      :key="ty"
                      :src="`/assets/icons/${ty}.svg`"
                    />
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  <RouterLink
                    class="underline"
                    :to="'/app/advertiser/products/view/' + product.uid"
                    >{{ product.name }}</RouterLink
                  >
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm">
                  <template
                    v-if="
                      product.promo &&
                      product.promo.endDate.seconds * 1000 >
                        new Date().getTime()
                    "
                    >&euro;
                    {{
                      (
                        product.price *
                        ((100 - product.promo.discount) / 100)
                      ).format()
                    }}
                  </template>
                  <template v-else>
                    &euro; {{ product.price.format() }}</template
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import Product from '../models/product'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'
import Loading from './Loading.vue'

const { t } = useI18n()
const products = ref<Product[]>([])
Product.featured().then((prod) => (products.value = prod as Product[]))

const getTypes = (product: Product) =>
  [
    'website',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'reddit',
    'telegram',
  ].filter(
    (type) =>
      (product[type as keyof Product] as unknown as Record<string, []>)?.length,
  )
</script>
