<template>
  <div :class="'grid xl:grid-cols-4 gap-3 my-4'">
    <InfoBox color="bg-blue-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('total-balances') }}</div>
        <div class="font-bold text-3xl">
          &euro; {{ totalBalances.format() }}
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-blue-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('deposits') }}</div>
        <div class="font-bold text-3xl">&euro; {{ deposits.format() }}</div>
      </div>
    </InfoBox>
    <InfoBox color="bg-green-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('purchases') }}</div>
        <div class="font-bold text-3xl">&euro; {{ purchases.format() }}</div>
      </div>
    </InfoBox>
    <InfoBox color="bg-red-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('refunds') }}</div>
        <div class="font-bold text-3xl">&euro; {{ refunds.format() }}</div>
      </div>
    </InfoBox>
    <InfoBox color="bg-yellow-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('total-purchases') }}</div>
        <div class="font-bold text-3xl">
          &euro; {{ purchasestotal.format() }}
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-purple-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('publisher-commissions') }}</div>
        <div class="font-bold text-3xl">
          &euro; {{ publishercommissions.format() }}
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-orange-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('affiliate-commissions') }}</div>
        <div class="font-bold text-3xl">
          &euro; {{ affiliatecommissions.format() }}
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-cyan-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('rankister-margin') }}</div>
        <div class="font-bold text-3xl">
          &euro; {{ rankistermargin.format() }} ({{
            rankistermarginperc.format()
          }}%)
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-cyan-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('rankister-net-margin') }}</div>
        <div class="font-bold text-3xl">
          &euro; {{ (rankistermargin - (totalDays * coeff)).format() }}<br />
          <small>&euro; {{ (totalDays * coeff).format() }}</small>
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-cyan-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('rankister-net-margin-percent') }}</div>
        <div class="font-bold text-3xl">
          {{ ((rankistermargin - (totalDays * coeff)) * 100 / purchasestotal).format() }} %<br />
          <small>{{ totalDays }} {{ t(totalDays == 1 ? 'day' : 'days')}}</small>
        </div>
      </div>
    </InfoBox>
    <InfoBox color="bg-cyan-500">
      <div class="text-center">
        <div class="text-sm uppercase">{{ t('rankister-net-margin-percent') }}</div>
        <div class="font-bold text-3xl">
          {{ (invoiced) }}<br />
        </div>
      </div>
    </InfoBox>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import InfoBox from '../InfoBox.vue'

const { t } = useI18n()
const props = defineProps<{
  transactions: any
  users: any
}>()

const dates = {
  from: [...props.transactions].sort((a: any, b: any) => a.createdAt.getTime() < b.createdAt.getTime() ? -1 : 1)[0]?.createdAt,
  to: [...props.transactions].sort((a, b: any) => a.createdAt.getTime() < b.createdAt.getTime() ? 1 : -1)[0]?.createdAt
}

const totalDays = Math.ceil(((dates.to?.getTime() ?? 0) - (dates.from?.getTime() ?? 0)) / (1000 * 3600 * 24))

const totalBalances = props.users
  .filter((i: any) => i.meta?.balance > 0)
  .reduce((acc: number, el: any) => acc + el.meta?.balance, 0)
const deposits = props.transactions
  .filter((i: any) => i.type === 'deposit')
  .reduce((acc: number, el: any) => acc + el.amount, 0)
const refunds = props.transactions
  .filter((i: any) => i.type === 'refund')
  .reduce((acc: number, el: any) => acc + -el.amount, 0)
const purchases = props.transactions
  .filter((i: any) => i.type === 'purchase')
  .reduce((acc: number, el: any) => acc + -el.amount, 0)

const affiliatecommissions = props.transactions
  .filter((i: any) => i.type === 'affiliate')
  .reduce((acc: number, el: any) => acc + -el.amount, 0)
const publishercommissions =
  props.transactions
    .filter((i: any) => i.type === 'order' && i.paymentMethod === 'order')
    .reduce((acc: number, el: any) => acc + -el.amount, 0) +
  props.transactions
    .filter(
      (i: any) =>
        i.type === 'refund' && i.paymentMethod === 'balance' && i.amount < 0,
    )
    .reduce((acc: number, el: any) => acc + -el.amount, 0)
const purchasestotal = purchases + refunds
const rankistermargin = purchasestotal + refunds - -publishercommissions - -affiliatecommissions
const rankistermarginperc = (rankistermargin * 100) / purchasestotal
const invoiced = 0
const coeff = 150
</script>
